<template>
  <form
    ref="boxForecast"
    data-test="card-article-portioning-forecast"
    class="card validation"
    novalidate
    @submit.prevent>

    <div class="card-header">
      <h3 class="card-title">Article portioning forecast</h3>
    </div>

    <div class="card-body">
      <div class="form-row">
        <div data-test="group-week-from" class="form-group col-md-6">
          <label class="form-label">Week From</label>
          <div class="input-group">
            <weekly-menu-filter @on-change="onWeekFromChange"/>
          </div>
        </div>
        <div data-test="group-week-to" class="form-group col-md-6">
          <label class="form-label">Week To</label>
          <div class="input-group">
            <weekly-menu-filter @on-change="onWeekToChange"/>
          </div>
        </div>
        <div
          data-test="group-pivot-toggle"
          class="form-group col-md-12 mb-2"
          :class="{'mb-0': withPivotTable}">
          <label class="custom-switch">
            <input
              v-model="withPivotTable"
              type="checkbox"
              class="custom-switch-input">
            <span class="custom-switch-indicator"></span>
            <span class="custom-switch-description form-label mb-0">With pivot table</span>
          </label>
        </div>
        <div
          v-show="!withPivotTable"
          data-test="group-customer-delivery-days"
          class="form-group col-md-12">
          <label class="form-label">Customer Delivery Days</label>
          <div class="input-group">
            <delivery-days-filter @on-change="onDayChange"/>
          </div>
        </div>
        <div
          v-show="!withPivotTable"
          data-test="group-category"
          class="form-group col-md-12">
          <label class="form-label">Category</label>
          <div class="input-group">
            <category-filter @select="onCategorySelect"/>
          </div>
        </div>
        <div class="col-md-12">
          <div v-if="errorMessage" class="invalid-feedback d-block">{{errorMessage}}</div>
        </div>
      </div>
    </div>
    <div class="card-footer text-right">
      <button
        type="submit"
        data-test="btn-export-article-portioning-forecast"
        class="btn btn-primary"
        :disabled="submitting"
        @click="exportArticlePortioningForecast"
      >
        Export Article Portioning Forecast
      </button>
    </div>
  </form>
</template>

<script>

import CategoryFilter from '@/components/filters/CategoryFilter';
import DeliveryDaysFilter from '@/components/filters/DeliveryDaysFilter';
import WeeklyMenuFilter from '@/components/filters/WeeklyMenuFilter';
import download from '@/mixins/download';
import {articleForecast} from '@/services';
import submitting from '@hellochef/shared-js/mixins/submitting';

export default {
  components: {
    WeeklyMenuFilter,
    DeliveryDaysFilter,
    CategoryFilter,
  },
  mixins: [
    download,
    submitting,
  ],
  data() {
    return {
      weeklyMenuFrom: null,
      weeklyMenuTo: null,
      days: [],
      categoryId: null,
      errorMessage: null,
      withPivotTable: true,
    };
  },
  methods: {
    onWeekFromChange(value) {
      this.weeklyMenuFrom = value;
    },
    onWeekToChange(value) {
      this.weeklyMenuTo = value;
    },
    onDayChange(value) {
      this.days = value.map(day => day.id);
    },
    onCategorySelect(value) {
      this.categoryId = value.id;
    },
    exportArticlePortioningForecast() {
      if (!this.weeklyMenuFrom || !this.weeklyMenuTo) {
        return alert('You must choose weekly menu range');
      }

      this.submitIt(async () => {
        try {
          // if pivot table is selected, export using the endpoint articleForecast.portioningExport
          if (!this.withPivotTable) {
            return this.download(await articleForecast.portioningExport({
              weekly_menu_from: this.weeklyMenuFrom,
              weekly_menu_to: this.weeklyMenuTo,
              delivery_days: this.days,
              category_id: this.categoryId,
            }));
          }

          this.download(await articleForecast.deliveryDaysPortioningExport({
            weekly_menu_from: this.weeklyMenuFrom,
            weekly_menu_to: this.weeklyMenuTo,
          }));
        }
        catch (e) {
          this.errorMessage = 'There is no data to download';
        }
      });
    },
  },
};
</script>
