<template>
  <div class="choose-market-item-modal">
    <b-modal
      v-model="safeShow"
      centered
      data-test="modal-choose-market-item"
      no-close-on-backdrop
      title="Add new market item"
      size="lg"
      hide-footer
      @close="$emit('close')">

      <template slot="modal-header-close">&nbsp;</template>

      <dimmer :active="listLoading">
        <div class="d-flex align-items-center gap-8">
          <label class="form-label ml-4 mb-0">Market Item Type: </label>

          <select v-model="marketItemTypeFilter" class="form-control custom-select w-auto">
            <option value="all">All</option>
            <option
              v-for="type in MARKET_ITEM_TYPES"
              :key="type"
              :value="type">
              {{type}}
            </option>
          </select>

          <button class="btn btn-outline-primary" @click="fetchRecipes">Search</button>
        </div>

        <div class="recipe-section">
          <div
            v-for="recipe in items"
            :key="recipe.id"
            class="recipe-list-item"
            @click="() => $emit('submit', recipe)">
            <i class="fe fe-plus plus-icon"/>
            <img
              class="recipe-img"
              :src="getTransformationUrl(recipe.photo, {width: 42, height: 42, crop: 'fill'})"
              :alt="recipe.name"/>
            <p class="recipe-name">{{recipe.name}}</p>
          </div>
        </div>
      </dimmer>
    </b-modal>
  </div>
</template>

<script>
import {MARKET_ITEM_TYPES} from '@/constants/market-item-types';
import list from '@/mixins/list';
import {market} from '@/services';

const MARKET_ITEM_LISTING_LIMIT = 100;

export default {
  name: 'ChooseMarketItemModal',
  mixins: [list],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      MARKET_ITEM_TYPES,
      loading: false,
      marketItemTypeFilter: 'all',
    };
  },
  computed: {
    safeShow: {
      get: function() { return this.show; },
      set: function(newValue) { this.$emit('show', newValue); },
    },
  },
  methods: {
    async fetchData(page) {
      const params = {
        limit: MARKET_ITEM_LISTING_LIMIT,
        page,
        'filters[archived]': 0,
        'filters[status]': ['ready-to-be-drafted'],
        'filters[item_type]': [this.marketItemTypeFilter],
      };

      return market.search(params);
    },
    fetchRecipes() {
      this.refresh();
    },
  },
};
</script>

<style scoped>
.choose-market-item-modal {
  display: block;
}

.recipe-section {
  overflow-y: auto;
  max-height: 60vh;
}

.recipe-list-item {
  display: flex;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid #E0E5EC;
  cursor: pointer;
}

.recipe-list-item:hover {
  background-color: #F5F8FA;
}

.recipe-list-item:last-child {
  border-bottom: none;
}

.plus-icon {
  font-size: 20px;
  color: #5DBB00;
  margin-left: 10px;
}

.recipe-img {
  width: 42px;
  height: 42px;
  margin-left: 20px;
  object-fit: cover;
  border-radius: 50%;
}

.recipe-name {
  margin: 0;
  margin-left: 8px;
  color: #467FCF;
  font-size: 15px;
}
</style>
