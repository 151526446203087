<template>
  <div class="container">
    <edit-header
      entity="articleRequisition"
      :error.sync="error"
      :error-message="errorMessage"
      :invalid.sync="invalid"
      :invalid-message="invalidMessage"
      :submitted.sync="submitted"
      :success-message="successMessage"
      :isNew="isNew">
      <template v-slot:options>
        <div v-if="!isNew && item.id" class="d-flex align-items-center">
          <div class="status-change-buttons">
            <button
              v-for="status in possibleStatusUpdates"
              :key="status"
              class="btn"
              :class="ArticleRequisitionStatus[status].btnClass"
              :disabled="submitting"
              @click="updateStatus(item.id, status)">
              Mark "{{status}}"
            </button>
          </div>
          <log-button-table
            v-if="item.id && item.modelName"
            v-model="item"
            class="ml-2"></log-button-table>
        </div>
      </template>
    </edit-header>

    <form
      ref="form"
      class="validation"
      novalidate
      @submit.prevent="submit">

      <!-- Requisition box -->
      <div class="card requisition">
        <div class="card-header active">
          <h5 class="mb-0">
            <div name="header">
              <i v-if="formInvalid('requisition')" class="fe fe-alert-circle text-danger mr-1"></i> Requisition
            </div>
          </h5>
        </div>

        <div class="collapse show">
          <div>
            <requisition-form
              ref="requisition"
              :isNew="isNew"
              :old="old"
              :errors.sync="response.error"
              :value.sync="item"
              :edit="isNew"></requisition-form>
          </div>
        </div>
      </div>

      <!-- Material form -->
      <accordion
        v-if="!isNew"
        custom-class="material"
        :option.sync="tabs.material"
        :hide-footer="true">
        <template v-slot:header>
          <i v-if="formInvalid('material')" class="fe fe-alert-circle text-danger mr-1"></i> Material type
        </template>
        <template v-slot:card-options>
          <a
            v-if="isEditable('material')"
            href="#"
            class="btn btn-link"
            @click.self.prevent="editForm('material')">Edit</a>
        </template>
        <material-type-form
          ref="material"
          :value.sync="articleData"
          :edit.sync="tabs.material.edit"
          :is-packable="isPackable"
          article-create-mode
          @valid="nextStep('material', articleData)"></material-type-form>
      </accordion>

      <!-- General form -->
      <accordion
        v-if="!isNew"
        custom-class="general"
        :option.sync="tabs.general"
        :hide-footer="true">
        <template v-slot:header>
          <i v-if="formInvalid('general')" class="fe fe-alert-circle text-danger mr-1"></i> General
        </template>
        <template v-slot:card-options>
          <a
            v-if="isEditable('general')"
            href="#"
            class="btn btn-link"
            @click.self.prevent="editForm('general')">Edit</a>
        </template>
        <general-form
          ref="general"
          :value.sync="articleData"
          :old="old"
          :uom="uom"
          :edit.sync="tabs.general.edit"
          :errors.sync="response.error"
          :show-checkbox="isAvailableForIgnoringQuantity(articleData)"
          article-create-mode
          @valid="nextStep('general', articleData)"
          @update-names="updateArticleNames(articleData)"></general-form>
      </accordion>

      <!-- Replenishments form -->
      <accordion
        v-if="!isNew"
        custom-class="replenishments"
        :option.sync="tabs.replenishment"
        :hide-footer="true">
        <template v-slot:header>
          <i v-if="formInvalid('replenishment')" class="fe fe-alert-circle text-danger mr-1"></i> Replenishment
        </template>
        <template v-slot:card-options>
          <a
            v-if="isEditable('replenishment')"
            href="#"
            class="btn btn-link"
            @click.self.prevent="editForm('replenishment')">Edit</a>
        </template>
        <replenishment-form
          ref="replenishment"
          :value.sync="articleData"
          :edit.sync="tabs.replenishment.edit"
          :errors.sync="response.error"
          :old="response.old"
          :uom="uom"
          @valid="nextStep('replenishment', articleData)">
          >
        </replenishment-form>
        <template v-slot:footer>
          <button class="btn btn-primary ml-auto">Apply</button>
        </template>
      </accordion>

      <!-- Logistics section is commented out as it is not required for this page -->
      <!-- <accordion
        v-if="!isNew"
        custom-class="logistics"
        :option.sync="tabs.logistics"
        :hide-footer="true">
        <template v-slot:header>
          <div class="d-flex align-items-center gap-4">
            <i v-if="formInvalid('logistics') || logisticsCustomError" class="fe fe-alert-circle text-danger mr-1"></i>
            Logistics
            <small v-if="logisticsCustomError" class="text-danger"> - {{logisticsCustomError}}</small>
          </div>
        </template>
        <template v-slot:card-options>
          <a
            v-if="isEditable('logistics')"
            href="#"
            class="btn btn-link"
            @click.self.prevent="editForm('logistics')">Edit</a>
        </template>
        <logistics-form
          ref="logistics"
          :value.sync="articleData"
          :edit.sync="tabs.logistics.edit"
          :uom="uom"
          @valid="nextStep('logistics', articleData)"></logistics-form>
        <template v-slot:footer>
          <button class="btn btn-primary ml-auto">Apply</button>
        </template>
      </accordion> -->

      <!-- Nutrition form -->
      <accordion
        v-if="!isNew"
        custom-class="nutrition"
        :option.sync="tabs.nutrition"
        :hide-footer="true">
        <template v-slot:header>
          <i v-if="formInvalid('nutrition')" class="fe fe-alert-circle text-danger mr-1"></i> Nutritional information
        </template>
        <template v-slot:card-options>
          <a
            v-if="isEditable('nutrition')"
            href="#"
            class="btn btn-link"
            @click.self.prevent="editForm('nutrition')">Edit</a>
        </template>
        <nutrition-form
          ref="nutrition"
          :value.sync="articleData"
          :edit.sync="tabs.nutrition.edit"
          :uom="uom"
          @valid="nextStep('nutrition', articleData)"></nutrition-form>
      </accordion>

      <!-- Storage form -->
      <accordion
        v-if="!isNew"
        custom-class="storage"
        :option.sync="tabs.storagePacking"
        :hide-footer="true">
        <template v-slot:header>
          <i v-if="formInvalid('storagePacking')" class="fe fe-alert-circle text-danger mr-1"></i> Storage and Packing
        </template>
        <template v-slot:card-options>
          <a
            v-if="isEditable('storagePacking')"
            href="#"
            class="btn btn-link"
            @click.self.prevent="editForm('storagePacking')">Edit</a>
        </template>
        <storage-packing-form
          ref="storagePacking"
          :value.sync="articleData"
          :edit.sync="tabs.storagePacking.edit"
          :uom="uom"
          :is-packable="isPackable"
          @valid="nextStep('storagePacking', articleData)"></storage-packing-form>
      </accordion>

      <!-- Other form -->
      <accordion
        v-if="!isNew"
        custom-class="other"
        :option.sync="tabs.other"
        :hide-footer="true"
        header="Other">
        <template v-slot:card-options>
          <a
            v-if="isEditable('other')"
            href="#"
            class="btn btn-link"
            @click.self.prevent="editForm('other')">Edit</a>
        </template>
        <other-form
          ref="other"
          :value.sync="articleData"
          :uom="uom"
          :edit.sync="tabs.other.edit"
          @valid="nextStep('other', articleData)"></other-form>
        <template v-slot:footer>
          <button class="btn btn-primary ml-auto">Apply</button>
        </template>
      </accordion>

      <!-- Allergens form -->
      <accordion
        :option.sync="tabs.allergens"
        :hide-footer="true"
        custom-class="allergens">
        <template v-slot:header>
          <i v-if="formInvalid('allergens')" class="fe fe-alert-circle text-danger mr-1"></i> Allergen Information
        </template>
        <allergens-form
          ref="allergens"
          :value.sync="articleData"
          @valid="nextStep('compositions', articleData)"></allergens-form>
        <template v-slot:footer>
          <button class="btn btn-primary ml-auto">Apply</button>
        </template>
      </accordion>

      <!-- Compositions form -->
      <accordion
        :option.sync="tabs.compositions"
        :hide-footer="true"
        custom-class="compositions">
        <template v-slot:header>
          <i v-if="formInvalid('compositions')" class="fe fe-alert-circle text-danger mr-1"></i> Article compositions
        </template>
        <template v-slot:card-options>
          <a
            v-if="isEditable('compositions')"
            href="#"
            class="btn btn-link"
            @click.self.prevent="editForm('compositions')">Edit</a>
        </template>
        <compositions-form
          ref="compositions"
          :value.sync="articleData"
          :edit.sync="tabs.compositions.edit"
          @valid="nextStep('compositions', articleData)"/>
        <template v-slot:footer>
          <button class="btn btn-primary ml-auto">Apply</button>
        </template>
      </accordion>

      <div class="row">
        <div class="col">
          <edit-footer
            :dirty="dirty"
            entity="Article Requisition"
            :hide-delete="false"
            :isNew="isNew"
            :submitting="submitting"
            create-title="Submit Request"></edit-footer>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import ArticleRequisitionStatus from '@/assets/enums/ArticleRequisitionStatus';
import Accordion from '@/components/Accordion';
import EditFooter from '@/components/EditFooter';
import EditHeader from '@/components/EditHeader';
import LogButtonTable from '@/components/LogButtonTable';
import articleEdit from '@/mixins/articleEdit';
import {articleRequisitions, articles} from '@/services';
import Permissions from '@hellochef/shared-js/enums/Permissions';
import AllergensForm from '../articles/forms/AllergensForm';
import CompositionsForm from '../articles/forms/CompositionsForm';
import GeneralForm from '../articles/forms/GeneralForm';
// import LogisticsForm from '../articles/forms/LogisticsForm';
import MaterialTypeForm from '../articles/forms/MaterialTypeForm';
import NutritionForm from '../articles/forms/NutritionForm';
import OtherForm from '../articles/forms/OtherForm';
import ReplenishmentForm from '../articles/forms/ReplenishmentForm';
import StoragePackingForm from '../articles/forms/StoragePackingForm';
import RequisitionForm from './forms/RequisitionForm';

export default {
  components: {
    Accordion,
    EditFooter,
    EditHeader,
    LogButtonTable,
    RequisitionForm,
    GeneralForm,
    MaterialTypeForm,
    // LogisticsForm,
    NutritionForm,
    OtherForm,
    ReplenishmentForm,
    StoragePackingForm,
    AllergensForm,
    CompositionsForm,
  },
  mixins: [
    /**
     * The `articleEdit` mixin contains shared functionality between
     * Article and Article Requisition create/edit pages
     * because their behaviour is mostly common.
     *
     * The original functionality on the Article page is set up as the
     * default behaviour in the articleEdit mixin.
     *
     * If something needs to change in the mixin that will only affect Requisition,
     * override those methods here instead of modifying the mixin.
     */
    articleEdit,
  ],
  data() {
    return {
      with: 'statusHistory.updatedBy',
      ArticleRequisitionStatus,
      item: {
        status: 'Requested',
        requested_by: null,
        requested_by_name: '',
        id: null,
        ingredient: {},
        brand: {},
        custom_brand_name: null,
        article_uom: '',
        article_portion_size: null,
        other_info: null,
        photo: null,
        article_price_per_unit_from: null,
        article_price_per_unit_to: null,
        article_estimated_weekly_consumption: null,
        article_features: '',
        article_should_not_have_allergens: '',
        status_history: [],
        created_at: '',
        updated_at: '',
      },
      articleData: {
        compositions: [],
        brand: null,
        dependencies: [],
        expiry_date_of_excess: '',
        expiry_time_after_packing: null,
        has_seasonality: false,
        how_to_taste_test: '',
        how_it_will_be_used: '',
        ingredient: null,
        is_temperature_controlled: false,
        is_weight_based: true,
        ignore_qty_in_price_calc: false,
        logistics: [],
        material_type: null,
        name: '',
        net_weight: null,
        net_weight_uom: null,
        nutritions: {
          carbohydrates: null,
          energyKCal: null,
          energyKJ: null,
          fat: null,
          fibre: null,
          protein: null,
          salt: null,
          saturates: null,
          sugarCarbohydrates: null,
          weight: 100,
        },
        qty_per_net_weight: null,
        qty_per_net_weight_uom: null,
        rank: '',
        replenishments: [],
        season_end_day: '',
        season_end_month: '',
        season_start_day: '',
        season_start_month: '',
        shelf_life_expiration_date: null,
        status: 'draft',
        storage_temperature_after_packing: null,
        storage_temperature_before_packing: null,
        allergens: [],
        is_import: false,
        countries: [],
        production_lead_time: 0,
        assign_bin_type: 'default',
        internal_waste_rule: null,
        external_waste_rule: null,
      },
    };
  },
  computed: {
    possibleStatusUpdates() {
      // get the list of possible status changes for the requisition
      const nextPossibleStatuses = (ArticleRequisitionStatus[this.item.status] ?? ArticleRequisitionStatus.default).nextPossibleStatuses;

      // remove status changes that can't be made by the logged in user
      return nextPossibleStatuses.filter(status => {
        const nextStatus = ArticleRequisitionStatus[status] ?? ArticleRequisitionStatus.default;
        return this.can(nextStatus.permission);
      });
    },
    formInvalid() {
      return ref => this.$refs[ref]?.$v?.$dirty && this.$refs[ref]?.$v?.$invalid;
    },
    isEditable() {
      return tab => !this.isEditing && !this.tabs?.[tab]?.edit && this.item?.status !== 'Reviewed' && this.can(Permissions.ARTICLES_REQUISITIONS_UPDATE);
    },
    isEditing() {
      return Object.values(this.tabs).some(item => item.disabled);
    },
    route() {
      return `/article-requisitions/${this.item.id}`;
    },
    isPackable() {
      return !this.articleData.material_type?.is_purchasable &&
        !this.articleData.material_type?.has_multiple_purchasable &&
        this.articleData.material_type?.has_logistics &&
        this.articleData.material_type?.is_recipe_material;
    },
  },
  methods: {
    editForm(step) {
      // reset tabs
      Object.keys(this.tabs).filter(tab => tab !== step).forEach(value => {
        this.tabs[value].edit = false;
      });

      this.tabs[step].edit = true;
      this.tabs[step].collapse = false;
    },
    fetchData(id) {
      const config = {
        params: {
          with: this.with,
        },
      };

      return articleRequisitions.getById(id, config);
    },
    async fetchDuplicate(id) {
      const config = {
        params: {
          with: this.with,
        },
      };

      const result = (await articleRequisitions.getById(id, config));

      // clean up incoming data
      delete result.item.id;
      delete result.item.status;

      // transform data to standardize item data
      this.item = (this.transformData(result)).item;
      this.articleData = {};
      this.old = Object.assign({}, this.item);
    },
    async handleDelete() {
      if (window.confirm('Deleting an article requisition cannot be undone. Are you sure you want to do this?')) {
        await articleRequisitions.deleteById(this.item.id);
        this.$router.push('/article-requisitions');
      }
    },
    async reset() {
      if (!this.isNew) {
        this.loading = true;

        const data = this.transformData(
          await this.fetchData(this.$route.params.id),
        );

        this.item = data.item;
        this.articleData = Object.assign({}, this.articleData, data.articleData);
        this.old = Object.assign({}, this.item);

        this.$nextTick(() => (this.forceDirty = false));

        this.loading = false;
      }
      else {
        Object.assign(this.$data, this.$options.data.apply(this));
      }
    },
    prepareRequisitionPayload(requisition, article = {}) {
      const features = this.transformStringToArray(requisition.article_features);
      const allergens = this.transformStringToArray(requisition.article_should_not_have_allergens);

      const data = Object.assign({}, requisition, {
        ingredient_id: requisition.ingredient.id,
        brand_id: requisition.brand?.id,
        photo_id: requisition.photo?.id,
        article_features: features,
        article_should_not_have_allergens: allergens,
        article_data: article,
      });

      delete data.ingredient;
      delete data.brand;
      delete data.photo;
      delete data.requested_by;
      delete data.requested_by_name;
      delete data.status_history;
      delete data.updated_at;

      return data;
    },
    submitData(item) {
      const articlePayload = this.isNew
        ? {}
        : this.prepareArticlePayload(
          this.articleData,
          this.articleData.logistics,
          this.articleData.replenishments,
          this.articleData.dependencies,
        );

      const requisition = this.prepareRequisitionPayload(item, articlePayload);

      return articleRequisitions.saveOrUpdate(requisition);
    },
    async createArticle() {
      const payload = this.prepareArticlePayload(
        this.articleData,
        this.prepareArticleLogisticsPayload(this.articleData.logistics),
        this.prepareArticleReplenishmentsPayload(this.articleData.replenishments),
        this.prepareArticleDependenciesPayload(this.articleData.dependencies),
      );

      payload.compositions = payload.compositions.map(composition => {
        if (typeof composition === 'object' && !Array.isArray(composition)) {
          return composition.id;
        }

        // composition articles just returns id
        return composition;
      });

      return articles.saveOrUpdate(payload);
    },
    async updateStatus(id, status) {
      if (ArticleRequisitionStatus[status].needsValidation && !(await this.validate())) return;

      this.submitting = true;
      let updateTheStatus = true;

      // create the article, if the status is reviewed
      if (status === 'Reviewed') {
        try {
          this.successMessage = 'Creating a new article.';
          await this.createArticle();
          this.successMessage = 'The article has been created successfully.';
        }
        catch (ex) {
          updateTheStatus = false;
          if (ex.response && ex.response.status === 422) {
            this.invalid = true;
            this.invalidMessage = `A new article could not be created. Errors: ${Object.values(ex.response.data.errors).join(' ')}`;
          }
          else {
            this.error = true;
            this.errorMessage = 'There was an error creating the article.';
            throw ex;
          }
        }
      }

      // if status is reviewed but article creation failed, do not update status
      if (!updateTheStatus) {
        this.submitting = false;
        return;
      };

      // update article requisition status
      const statusUpdateResponse = await articleRequisitions.updateStatus(id, status);
      this.item.status = statusUpdateResponse.item.status;

      this.submitted = true;
      this.successMessage = `${this.successMessage} Article status has been updated successfully.`;

      this.submitting = false;
    },
    async validate() {
      let valid = true;
      let scrollToErrorTop = -1;
      this.logisticsCustomError = null;

      // control scrolling in edit mixin
      this.customScrollToError = true;

      // validate the requisition form manually
      if (this.isNew) {
        const requisitionForm = this.$refs.requisition?.$v;

        requisitionForm?.$touch(); // eslint-disable-line
        await this.$nextTick();

        if (requisitionForm?.$invalid) {
          valid = false;
        }
      }

      const tabs = Object.keys(this.tabs).filter(tab => !this.tabs[tab].disabled && this.tabs[tab].display);

      // on submit validation, shouldn't be sent to api if any section is still not valid
      for (let i = 0; i <= tabs.length - 1; i++) {
        this.$refs[tabs[i]]?.$v?.$touch(); // eslint-disable-line

        await this.$nextTick();

        // this will revalidate our model first
        if (this.$refs[tabs[i]]?.$v?.$invalid) {
          this.tabs[tabs[i]].edit = true;
          this.tabs[tabs[i]].disabled = false;
          this.tabs[tabs[i]].collapse = false;

          // scroll to form error on the last error seen
          scrollToErrorTop = this.$refs[tabs[i]].$parent.$el?.offsetTop;

          if (valid) { valid = false; }
        }

        if (!valid) break;

        // check if at least 'each' logistics added
        if (tabs[i] === 'logistics' && this.articleData.logistics.length >= 1) {
          const validLogistics = valid = this.validateLogistics(this.articleData);
          if (!validLogistics) {
            scrollToErrorTop = this.$refs.logistics.$parent.$el?.offsetTop;
          }
        }
      }

      if (scrollToErrorTop > -1) {
        window.scrollTo({
          behavior: 'smooth',
          top: scrollToErrorTop,
        });
      }

      return valid;
    },
    transformStringToArray(value = '') {
      return value.split(',').map(f => f.trim());
    },
    transformArraysToStrings(requisition) {
      requisition.article_features = requisition.article_features.join(', ');
      requisition.article_should_not_have_allergens = requisition.article_should_not_have_allergens.join(', ');

      return requisition;
    },
    transformData(result) {
      const articleData = Object.assign({}, {
        brand: result.item.brand,
        ingredient: result.item.ingredient,
      }, result.item.article_data);

      delete result.item.article_data;

      this.updateFormState(articleData);
      this.transformArraysToStrings(result.item);
      this.transformArticleNutritions(articleData, this.articleData.nutritions);

      return {
        item: result.item,
        articleData,
      };
    },
  },
};
</script>

<style scoped>
.status-change-buttons>.btn+.btn {
  margin-left: 0.5rem;
}
</style>
