<template>
  <div class="container">
    <b-modal
      v-model="showSubmitDialog"
      @ok="submit"
    >
      <template slot="modal-header-close"><wbr/></template>
      Are you sure you want to create the PO?
      <small v-if="!sendEmailToSupplier" class="text-danger d-block">
        Please note that the email will not be sent to the supplier.
      </small>
    </b-modal>
    <form
      class="custom-form"
      @submit.prevent="submit"
    >
      <div class="row">
        <div class="col-12 d-flex justify-content-center">
          <div v-if="showSuccessDialog" class="col-12 alert alert-icon alert-success alert-dismissible">
            <button
              type="button"
              class="close"
              @click="showSuccessDialog = false"></button>
            <i class="fe fe-check mr-2" aria-hidden="true"></i>
            Your changes have been saved successfully.
          </div>
        </div>
        <div class="col-12">
          <div class="card">
            <div class="card-header mt-3">
              <div class="card-title">
                <h3>Create Purchase Orders Manually</h3>
              </div>
              <div class="card-options">
                <a
                  v-if="!edit"
                  href="#"
                  class="btn btn-link"
                  @click.prevent="edit = true"
                >
                  Edit Filter
                </a>
              </div>
            </div>
            <div class="card-body">

              <div class="row mt-5 mb-5">
                <div class="col-12 mb-5">
                  <manual-purchase-order-filter
                    ref="filter"
                    :edit="edit"
                    :filter.sync="filter"
                    @valid="applyFilter"/>
                </div>
              </div>

              <!-- Article Table -->
              <div  v-if="!edit"  class="row mt-5">
                <div class="col-12 table-container">
                  <article-table
                    :articles.sync="selectedArticles"
                    :isCash="isCash"
                    @delete="deleteArticle"
                  />
                </div>
              </div>
              <!-- Article Table End -->

              <b-modal
                v-model="showDialog"
                centered
                size="lg"
                title="Add Article"
                hide-footer
              >
                <template slot="modal-header-close"><wbr/></template>
                <manual-purchase-order-form
                  :articles="articles"
                  :isCash="isCash"
                  @submit="addArticle"
                />
              </b-modal>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!edit" class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div v-if="hasError" class="alert alert-icon alert-danger alert-dismissible">
                    <button
                      type="button"
                      class="close"
                      @click="hasError = false"></button>
                    <i class="fe fe-alert-triangle mr-2" aria-hidden="true"></i>
                    Make sure that the price and quantity values are correct
                  </div>
                </div>
                <div class="col-12">
                  <dimmer :active="loading">
                    <div class="d-flex align-items-center gap-12">
                      <button
                        type="button"
                        class="btn"
                        @click="() => showDialog = true"
                      >
                        Add Article
                      </button>
                      <template v-if="selectedArticles.length > 0 && can(uiPermissions.PURCHASE_ORDERS_MANUAL_CREATE)">
                        <button
                          type="button"
                          class="btn btn-primary"
                          :disabled="loading"
                          @click="showSubmitDialog = true"
                        >
                          Create PO
                        </button>
                        <label class="form-check m-0">
                          <input
                            v-model="sendEmailToSupplier"
                            data-test="checkbox-send-email-to-supplier"
                            class="form-check-input"
                            type="checkbox">
                          <span class="form-check-label small">Send email to supplier?</span>
                        </label>
                      </template>
                    </div>
                  </dimmer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import ArticleTable from './ArticleTable';
import ManualPurchaseOrderForm from './ManualPurchaseOrderForm';
import ManualPurchaseOrderFilter from './filters/ManualPurchaseOrderFilter';
import {articlesDropdown, purchaseOrders} from '@/services';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';

export default {
  name: 'ManualPurchaseOrder',
  components: {

    ArticleTable,
    ManualPurchaseOrderForm,
    ManualPurchaseOrderFilter,
  },
  data() {
    return {
      ...this.setInitialData(),
      articles: [],
      loading: false,
      showSubmitDialog: false,
      sendEmailToSupplier: true,
    };
  },
  computed: {
    isCash() {
      return this.filter.POType?.toLowerCase() === 'cash';
    },
  },
  methods: {
    async submit() {
      const POTypeToCompare = this.filter.POType.toLowerCase();

      this.showSubmitDialog = false;
      this.loading = true;

      this.hasError = this.selectedArticles.some(article => !article.quantity || (POTypeToCompare === 'manual' && !article.selectedReplenishment.cost_price));
      if (this.hasError) {
        return;
      }

      let payload = null;
      if (POTypeToCompare === 'cash') {
        payload = [{
          weekly_menu_id: this.filter.weeklyMenu.id,
          delivery_days: this.filter.days.map(({id}) => id),
          delivery_date: this.selectedArticles?.[0]?.deliveryDate,
          lead_time: this.selectedArticles?.[0]?.selectedReplenishment?.lead_time,
          purchase_type: POTypeToCompare,
          articles: this.selectedArticles.map(
            ({articleId, quantity, categoryId, selectedReplenishment: {quickbook_name, ordering_uom_id, cost_price}}) => {
              return {
                article_id: articleId,
                qb_name: quickbook_name,
                suggested_qty: 0,
                decided_qty: quantity,
                oum_id: ordering_uom_id,
                category_id: categoryId,
              };
            }),
        }];
      }
      else {
        const groupedSelectedArticles = groupBy(
          this.selectedArticles,
          ({deliveryDate, selectedReplenishment: {supplier_id}}) => `"${deliveryDate}${supplier_id}"`,
        );

        payload = map(groupedSelectedArticles, selectedArticles => {
          return {
            weekly_menu_id: this.filter.weeklyMenu.id,
            delivery_days: this.filter.days.map(({id}) => id),
            supplier_id: selectedArticles?.[0]?.selectedReplenishment?.supplier_id,
            delivery_date: selectedArticles?.[0]?.deliveryDate,
            lead_time: selectedArticles?.[0]?.selectedReplenishment?.lead_time,
            purchase_type: POTypeToCompare,
            articles: selectedArticles.map(
              ({articleId, quantity, categoryId, selectedReplenishment: {quickbook_name, ordering_uom_id, cost_price}}) => {
                return {
                  article_id: articleId,
                  qb_name: quickbook_name,
                  suggested_qty: 0,
                  decided_qty: quantity,
                  oum_id: ordering_uom_id,
                  category_id: categoryId,
                  total_price: cost_price,
                };
              }),
          };
        });
      }

      try {
        await purchaseOrders.place({
          purchase_orders: payload,
          send_email_to_supplier: this.sendEmailToSupplier,
        });

        Object.assign(this.$data, this.setInitialData());
        this.$refs.filter.$v.$reset();
        this.showSuccessDialog = true;
      }
      catch (ex) {
        this.hasError = true;
      }

      this.loading = false;
    },
    setInitialData() {
      return {

        edit: true,
        hasError: false,
        showSuccessDialog: false,
        showDialog: false,
        selectedArticles: [],
        filter: {
          days: [],
          weeklyMenu: null,
          POType: null,
        },
      };
    },
    async getArticlesDropDown() {
      this.articles = (await articlesDropdown.getByParameters({'gnfr': this.filter.POType?.toLowerCase() === 'gnfr'}))
        .map(article => Object.assign(article, {customName: `${article.id} - ${article.name}`}));
    },
    addArticle(article) {
      this.showDialog = false;
      this.selectedArticles.push(article);
    },
    deleteArticle(deletedIndex) {
      this.selectedArticles = this.selectedArticles.filter((selectedArticle, index) => index !== deletedIndex);
    },
    applyFilter() {
      this.edit = false;
      this.getArticlesDropDown();
    },
  },
};
</script>

<style scoped>
  .table-container {
    max-height: 500px;
    overflow: scroll;
  }
</style>
