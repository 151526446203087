import {capitalize, lowerCase} from 'lodash';

export const jsonToCsv = (jsonArray, options = {}) => {
  const {excludeColumns = [], includeColumns = [], filename = 'data'} = options;
  let header;
  const replacer = (key, value) => {
    switch (typeof value) {
    case 'boolean':
      return value ? 'Yes' : 'No';
    case 'object':
      return value === null ? '' : JSON.stringify(value);
    default:
      return value === null ? '' : value;
    }
  };
  if (includeColumns.length > 0) {
    header = includeColumns;
  }
  else {
    header = Object.keys(jsonArray[0]).filter(key => !excludeColumns.includes(key));
  }

  let csv = jsonArray.map(row => header.map(fieldName =>
    JSON.stringify(row[fieldName], replacer)).join(','));
  csv.unshift(header.map(i => capitalize(lowerCase(i))).join(','));
  csv = csv.join('\r\n');

  const blob = new Blob([csv], {type: 'text/csv'});
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', filename + '.csv');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
