<template>
  <form
    ref="boxForecast"
    class="card validation"
    novalidate
    @submit.prevent>
    <div class="card-header">
      <h3 class="card-title">Box Forecast</h3>
    </div>
    <div class="card-body">
      <div class="form-row">
        <div class="form-group col-md-6">
          <label class="form-label">Week From</label>
          <div class="input-group">
            <weekly-menu-filter @on-change="onWeekFromChange"/>
          </div>
        </div>
        <div class="form-group col-md-6">
          <label class="form-label">Week To</label>
          <div class="input-group">
            <weekly-menu-filter @on-change="onWeekToChange"/>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer text-right">
      <button
        type="submit"
        class="btn btn-primary"
        :disabled="submitting"
        @click="exportForecast"
      >
        Export Forecast
      </button>
    </div>
  </form>
</template>

<script>
import {boxesForecastExport} from '@/services';
import WeeklyMenuFilter from '@/components/filters/WeeklyMenuFilter';
import download from '@/mixins/download';
import submitting from '@hellochef/shared-js/mixins/submitting';

export default {
  components: {

    WeeklyMenuFilter,
  },
  mixins: [

    download,
    submitting,
  ],
  data() {
    return {
      weeklyMenuFrom: null,
      weeklyMenuTo: null,
    };
  },
  methods: {
    onWeekFromChange(value) {
      this.weeklyMenuFrom = value;
    },
    onWeekToChange(value) {
      this.weeklyMenuTo = value;
    },
    exportForecast() {
      this.submitIt(async () => {
        this.download(await boxesForecastExport.exportByParameters({
          weekly_menu_from: this.weeklyMenuFrom,
          weekly_menu_to: this.weeklyMenuTo,
        }));
      });
    },
  },
};
</script>
