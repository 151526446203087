<template>
  <b-modal
    v-model="safeShow"
    size="lg"
    :no-close-on-backdrop="!cid"
    id="modal-complaint"
    @show="handleModalShow">
    <template slot="modal-header-close"><wbr/></template>

    <template slot="modal-title">
      <div class="d-flex align-items-center">
        <i
          v-if="complaintsIndex"
          class="fe fe-arrow-left-circle mr-4 h-20 cursor-pointer"
          style="font-size: 1.5rem"
          @click="() => this.complaintsIndex--"></i>
        <div style="font-size: 1.5rem;">Complaint {{complaintsIndex + 1}} of {{complaints.length}}</div>
        <i
          v-if="complaintsIndex !== complaints.length - 1"
          class="fe fe-arrow-right-circle ml-4 h-20 cursor-pointer"
          style="font-size: 1.5rem"
          @click="() => this.complaintsIndex++"></i>
      </div>
    </template>

    <template slot="modal-footer">
      <strong v-if="willRefundOrder" class="text-danger">Warning! This order will be marked as refunded.</strong>
      <div class="flex-1"></div>
      <div>
        <template v-if="!cid">
          <button
            v-if="complaintsIndex"
            class="btn btn-danger mr-2"
            @click.prevent="removeComplaint">
            <i class="fe fe-trash mr-1"></i> Remove
          </button>
          <button class="btn btn-secondary" @click.prevent="addAnotherComplaint">
            Add another complaint
            <i class="fe fe-chevron-right ml-1"></i>
          </button>
        </template>
      </div>
      <button
        class="btn btn-primary"
        type="submit"
        form="complaintForm">
        Submit
      </button>
    </template>

    <div v-show="invalid" class="alert alert-icon alert-danger alert-dismissible">
      <button
        type="button"
        class="close"
        @click="invalid = false"></button>
      <i class="fe fe-alert-triangle mr-2" aria-hidden="true"></i> {{invalidMessage || 'Some values are still missing or invalid. Please check them!'}}
    </div>

    <form
      ref="complaintForm"
      id="complaintForm"
      @submit.prevent="submit">
      <dimmer :active="loading">
        <div v-if="cid" class="row">
          <div class="col-12">
            <label class="text-danger">
              <div class="alert alert-warning">
                <strong>Note:</strong> Editing this complaint will not update any existing order change requests.
              </div>
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="form-label">Issue</label>
              <div>
                <b-dropdown
                  :disabled="cid ? true : false"
                  :text="issueTitle"
                  class="complaint-issue-dropdown"
                >
                  <template v-for="issue in issues">
                    <div :key="issue.id">
                      <b-dropdown-group
                        v-if="issue.subCategories.length"
                        :header="issue.title"
                        :id="`group-${issue.id}`">
                        <b-dropdown-item-button
                          v-for="subCategory in issue.subCategories"
                          v-b-tooltip="subCategory.hint"
                          :key="subCategory.id"
                          @click="selectComplaint(subCategory, issue.title)"
                        >
                          {{subCategory.title}}
                        </b-dropdown-item-button>
                      </b-dropdown-group>
                      <b-dropdown-item-button
                        v-else
                        v-b-tooltip="issue.hint"
                        :key="issue.id"
                        :disabled="disableBoxCancellation(issue.title)"
                        @click="selectComplaint(issue, issue.title)"
                      >
                        {{issue.title}}
                      </b-dropdown-item-button>
                      <b-dropdown-divider/>
                    </div>
                  </template>
                </b-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div v-if="['Quality issue', 'Missing items'].includes(complaint ? complaint.category : '')" class="row row-deck">
          <div class="col-7">
            <div class="card">
              <div class="card-header">
                <div class="card-title">
                  Choose recipe
                </div>
              </div>

              <div class="table-responsive" style="max-height: 15rem; overflow-y: auto;">
                <table class="card-table table-vcenter table table-outline">
                  <tr
                    v-for="recipe in order.recipes"
                    :key="recipe.id"
                    :class="{'bg-primary': complaint.recipes.find(item => item.id === recipe.id)}"
                    class="cursor-pointer"
                    @click.prevent="() => openIngredientsModal(recipe.id)">
                    <td>
                      <div :class="{'text-white': complaint.recipes.find(item => item.id === recipe.id)}">
                        R{{recipe.id}}: {{recipe.name}}
                      </div>
                    </td>
                    <td class="w-1 text-right">
                      <router-link
                        :to="`/recipes/${recipe.id}`"
                        target="_blank"
                        class="ml-2"
                        :class="{'text-white': complaint.recipes.find(item => item.id === recipe.id)}">
                        <i class="fe fe-external-link"></i>
                      </router-link>
                    </td>
                  </tr>
                  <!-- Market recipes -->
                  <template v-if="marketRecipes.length">
                    <tr>
                      <td colspan="100%">
                        <b>Market Recipes:</b>
                      </td>
                    </tr>
                    <tr
                      v-for="recipe in marketRecipes"
                      :key="recipe.recipe_id"
                      :class="{'bg-primary': complaint.recipes.find(item => item.id === recipe.recipe_id)}"
                      class="cursor-pointer"
                      @click.prevent="() => openIngredientsModal(recipe.recipe_id)">
                      <td>
                        <div :class="{'text-white': complaint.recipes.find(item => item.id === recipe.recipe_id)}">
                          R{{recipe.recipe_id}}: {{recipe.name}}
                        </div>
                      </td>
                      <td class="w-1 text-right">
                        <router-link
                          :to="`/recipes/${recipe.recipe_id}`"
                          target="_blank"
                          class="ml-2"
                          :class="{'text-white': complaint.recipes.find(item => item.id === recipe.recipe_id)}">
                          <i class="fe fe-external-link"></i>
                        </router-link>
                      </td>
                    </tr>
                  </template>
                </table>
              </div>
            </div>
          </div>
          <div class="col-5">
            <div class="card">
              <div class="card-header">
                <div class="card-title">
                  Ingredient selected <span class="text-danger">*</span>
                </div>
              </div>
              <div class="table-responsive" style="max-height: 15rem; overflow-y: auto">
                <table class="card-table table-vcenter table text-nowrap table-hover">
                  <template v-for="recipe in complaint.recipes">
                    <tr v-for="ingredient in recipe.ingredients" :key="`${ingredient.id}-${recipe.number}`">
                      <td>{{ingredient.name}}</td>
                      <td class="w-1">R{{recipe.id}}</td>
                    </tr>
                  </template>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div v-if="['Recipe issues'].includes(complaint ? complaint.category : '')" class="row">
          <div class="col">
            <div class="card">
              <div class="card-header">
                <div class="card-title">
                  Choose recipe <span class="text-danger">*</span>
                </div>
              </div>
              <div class="table-responsive" style="max-height: 15rem; overflow-y: auto;">
                <table v-if="cid" class="card-table table-vcenter table table-outline">
                  <tr v-for="recipe in order.recipes" :key="recipe.id">
                    <td class="w-1">
                      <label class="custom-control custom-checkbox mb-0">
                        <input
                          v-model="complaint.recipes"
                          type="checkbox"
                          class="custom-control-input"
                          :value="{id: recipe.id}"
                          :checked="complaint.recipes.find(item => item.id === recipe.id)"
                          @change.prevent="complaint.recipes = [{id: recipe.id}]">
                        <span class="custom-control-label"></span>
                      </label>
                    </td>
                    <td>
                      R{{recipe.id}}: {{recipe.name}}
                    </td>
                    <td class="w-1 text-right">
                      <router-link
                        :to="`/recipes/${recipe.id}`"
                        class="ml-2"
                        target="_blank">
                        <i class="fe fe-external-link"></i>
                      </router-link>
                    </td>
                  </tr>
                  <!-- Market recipes -->
                  <template v-if="marketRecipes.length">
                    <tr>
                      <td colspan="100%">
                        <b>Market Recipes:</b>
                      </td>
                    </tr>
                    <tr v-for="recipe in marketRecipes" :key="recipe.recipe_id">
                      <td class="w-1">
                        <label class="custom-control custom-checkbox mb-0">
                          <input
                            v-model="complaint.recipes"
                            type="checkbox"
                            class="custom-control-input"
                            :value="{id: recipe.recipe_id}"
                            :checked="complaint.recipes.find(item => item.id === recipe.recipe_id)"
                            @change.prevent="complaint.recipes = [{id: recipe.recipe_id}]">
                          <span class="custom-control-label"></span>
                        </label>
                      </td>
                      <td>
                        R{{recipe.recipe_id}}: {{recipe.name}}
                      </td>
                      <td class="w-1 text-right">
                        <router-link
                          :to="`/recipes/${recipe.recipe_id}`"
                          class="ml-2"
                          target="_blank">
                          <i class="fe fe-external-link"></i>
                        </router-link>
                      </td>
                    </tr>
                  </template>
                </table>
                <table v-else class="card-table table-vcenter table table-outline">
                  <tr v-for="recipe in order.recipes" :key="recipe.id">
                    <td class="w-1">
                      <label class="custom-control custom-checkbox mb-0">
                        <input
                          v-model="complaint.recipes"
                          type="checkbox"
                          class="custom-control-input"
                          :value="{id: recipe.id}"
                          :checked="complaint.recipes.find(item => item.id === recipe.id)">
                        <span class="custom-control-label"></span>
                      </label>
                    </td>
                    <td>
                      R{{recipe.id}}: {{recipe.name}}
                    </td>
                    <td class="w-1 text-right">
                      <router-link
                        :to="`/recipes/${recipe.id}`"
                        class="ml-2"
                        target="_blank">
                        <i class="fe fe-external-link"></i>
                      </router-link>
                    </td>
                  </tr>
                  <!-- Market recipes -->
                  <template v-if="marketRecipes.length">
                    <tr>
                      <td colspan="100%">
                        <b>Market Recipes:</b>
                      </td>
                    </tr>
                    <tr v-for="recipe in marketRecipes" :key="recipe.recipe_id">
                      <td class="w-1">
                        <label class="custom-control custom-checkbox mb-0">
                          <input
                            v-model="complaint.recipes"
                            type="checkbox"
                            class="custom-control-input"
                            :value="{id: recipe.recipe_id}"
                            :checked="complaint.recipes.find(item => item.id === recipe.recipe_id)">
                          <span class="custom-control-label"></span>
                        </label>
                      </td>
                      <td>
                        R{{recipe.recipe_id}}: {{recipe.name}}
                      </td>
                      <td class="w-1 text-right">
                        <router-link
                          :to="`/recipes/${recipe.recipe_id}`"
                          class="ml-2"
                          target="_blank">
                          <i class="fe fe-external-link"></i>
                        </router-link>
                      </td>
                    </tr>
                  </template>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="form-label">Resolution offered <span class="text-danger">*</span></label>
              <div class="d-flex">
                <select
                  v-model="complaint.resolution"
                  class="form-control custom-select col-8"
                  required
                  :disabled="cid || !complaint.complaint_id"
                  @change.prevent="handleResolutionChange">
                  <option value="" disabled>Select a resolution</option>
                  <option
                    v-for="(value, key) in ResolutionTypes"
                    :key="key"
                    :value="key"
                    :disabled="key === 'refund' && disableRefundForCancellation"
                  >
                    {{value}}
                  </option>
                </select>
                <input
                  v-if="cid && complaint.resolution === 'refund'"
                  v-model="complaint.resolutionAmount"
                  type="number"
                  class="form-control ml-2"
                  placeholder="Refund value"
                  :disabled="cid"
                />
                <input
                  v-if="complaint.resolution === 'storeCredit'"
                  v-model="complaint.resolutionAmount"
                  type="number"
                  class="form-control ml-2"
                  placeholder="Store credit value"
                  required
                  :disabled="cid"/>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!cid && complaint.resolution === 'refund'" class="row">
          <div class="col-7">
            <div class="form-group">
              <label class="form-label">Transactions <span class="text-danger">*</span></label>
              <label class="text-muted mb-2">
                Please select a transaction for partial or full refund
              </label>
              <div class="card mb-4">
                <div class="table-responsive">
                  <table class="table card-table table-vcenter table-outline complaint-refund-table">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Original Amount</th>
                        <th>Refund Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="!complaint.transactions.length">
                        <td colspan="100%" class="text-center">No transactions available to refund</td>
                      </tr>
                      <tr
                        v-for="transaction in complaint.transactions"
                        :key="transaction.id"
                        class="cursor-pointer"
                        :class="{'table-success': transaction.checked}"
                        @click="transaction.checked = !transaction.checked">
                        <td>
                          <label class="custom-control custom-checkbox mb-0">
                            <input
                              v-model="transaction.checked"
                              :value="transaction.checked"
                              :checked="transaction.checked"
                              type="checkbox"
                              class="custom-control-input"
                              @click="transaction.checked = !transaction.checked">
                            <span class="custom-control-label"></span>
                          </label>
                        </td>
                        <td class="text-center">
                          {{transaction.amount}}
                        </td>
                        <td>
                          <input
                            v-model="transaction.refund"
                            class="form-control w-auto text-center"
                            step="any"
                            min="1"
                            :max="transaction.refund"
                            :readonly="!transaction.checked"
                            type="number"
                            @click.stop/>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <label
                class="custom-control custom-checkbox d-inline-flex mb-0">
                <input
                  v-model="complaint.markOrderRefunded"
                  :value="complaint.markOrderRefunded"
                  :checked="complaint.markOrderRefunded"
                  type="checkbox"
                  name="markOrderRefunded"
                  class="custom-control-input"
                  @click="complaint.markOrderRefunded = !complaint.markOrderRefunded">
                <span class="custom-control-label">
                  Mark this order as refunded
                </span>
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-7">
            <div class="form-group">
              <label class="form-label">Comments</label>
              <textarea
                v-model="complaint.comment"
                class="form-control"
                rows="5">
              </textarea>
            </div>
          </div>
        </div>
      </dimmer>
    </form>

    <b-modal
      v-model="ingredientsModal"
      :title="`Choose missing ingredients for Recipe ${(this.selectedRecipe || {}).number || ''}`"
      size="lg"
      id="modal-complaint-ingredients">
      <template slot="modal-header-close"><wbr/></template>

      <div v-if="cid" class="custom-controls-stacked">
        <label
          v-for="ingredient in ingredients"
          :key="ingredient.id"
          class="custom-control custom-checkbox d-inline-flex col-4">
          <input
            v-model="selectedIngredients"
            type="checkbox"
            name="ingredient"
            class="custom-control-input"
            :value="ingredient.id"
            :checked="selectedIngredients.includes(ingredient.id)"
            @change.prevent="selectedIngredients = [ingredient.id]">
          <span class="custom-control-label">{{ingredients.find(item => item.id === ingredient.id).name}}</span>
        </label>
      </div>
      <div v-else class="custom-controls-stacked">
        <label
          v-for="ingredient in ingredients"
          :key="ingredient.id"
          class="custom-control custom-checkbox d-inline-flex col-4">
          <input
            v-model="selectedIngredients"
            type="checkbox"
            class="custom-control-input"
            :value="ingredient.id">
          <span class="custom-control-label">{{ingredients.find(item => item.id === ingredient.id).name}}</span>
        </label>
      </div>

      <template slot="modal-footer">
        <div></div>
        <button class="btn btn-primary" @click.prevent="updateIngredients">Ok</button>
      </template>
    </b-modal>
  </b-modal>
</template>

<script>

import {complaints, orderComplaints, payments, recipes, storeCredit} from '@/services';
import IssueTypes from '@hellochef/shared-js/enums/IssueTypes';
import PaymentTypes from '@hellochef/shared-js/enums/PaymentTypes';
import ResolutionTypes from '@hellochef/shared-js/enums/ResolutionTypes';
import {captureException} from '@sentry/vue';
import {mapState} from 'vuex';

const MAX_NO_ORDER_CANCELLATION_FOR_REPEAT_OFFENDER = 3;

export default {
  props: [
    'cid',
    'order',
    'show',
    'repeatOffender',
    'marketRecipes',
  ],
  data() {
    return {
      IssueTypes,
      PaymentTypes,
      ResolutionTypes,
      issues: [],
      complaints: [{
        category: '',
        comment: '',
        order: null,
        recipes: [],
        reportedBy: null,
        resolution: '',
        resolutionAmount: 0,
        reason: '',
        transactions: [],
        markOrderRefunded: false,
      }],
      complaintsIndex: 0,
      ingredients: [],
      ingredientsModal: false,
      invalid: false,
      invalidMessage: '',
      loading: false,
      recipeId: null,
      selectedIngredients: [],
      selectedIssueTitle: '',
      transactions: [],
    };
  },
  computed: {
    ...mapState({
      customer: state => state.auth.customer,
      user: state => (state.auth.customer || {}).user,
    }),
    complaint() {
      return this.complaints[this.complaintsIndex];
    },
    issueTitle() {
      const title = this.selectedIssueTitle || 'Select issue';
      let selectedIssue = null;

      if (this.issues && this.complaint?.complaint_id) {
        selectedIssue =
        this.issues.find(issue => issue.id === this.complaint.complaint_id) ||
        this.issues.flatMap(issue => issue.subCategories).find(subCategory => subCategory.id === this.complaint.complaint_id);
      }

      return selectedIssue ? selectedIssue.title : title;
    },
    safeShow: {
      get() { return this.show; },
      set(newValue) {
        if (!newValue) {
          this.resetComplaints();
        }

        this.$emit('update:show', newValue);
        this.$emit('update:cid', null);
      },
    },
    selectedRecipe() {
      return this.order.recipes.find(item => item.id === this.recipeId) || {};
    },
    disableRefundForCancellation() {
      if (this.selectedIssueTitle !== 'Box Cancellation' && this.complaint.resolution !== 'refund') return false;

      const noOfCancellation = this.repeatOffender?.no_of_cancellations;
      return noOfCancellation >= MAX_NO_ORDER_CANCELLATION_FOR_REPEAT_OFFENDER;
    },
    disableBoxCancellation() {
      return issue =>
        issue === 'Box Cancellation' &&
        this.disableRefundForCancellation &&
        this.complaint.resolution === 'refund';
    },
    willRefundOrder() {
      return this.complaints.some(item => item.markOrderRefunded);
    },
  },
  watch: {
    async cid() {
      if (!this.cid) {
        this.resetComplaints();
        return;
      }

      const selected = this.order.complaints.find(item => item.id === this.cid);
      if (!selected) {
        return;
      }

      this.selectedIssueTitle = selected.complaint?.title;
      Object.assign(this.complaint, {
        category: selected.complaint?.category ? selected.complaint.category.title : selected.complaint?.title,
        comment: selected.comment || '',
        recipes: [],
        resolution: selected.resolution,
        resolutionAmount: selected.resolutionAmount,
        complaint_id: selected.complaint?.id,
      });

      if (selected.recipe && selected.recipe.id) {
        this.recipeId = selected.recipe.id;
        this.complaint.recipes = [
          {id: selected.recipe.id},
        ];
      }

      if (selected.ingredient && selected.ingredient.id) {
        await this.getIngredientList(this.recipeId);

        this.selectedIngredients = [selected.ingredient.id];
        this.updateIngredients();
      }
    },
  },
  created() {
    complaints.getByParameters().then(response => this.issues = response.complaints);
  },
  mounted() {
    this.assignTransactions();
  },
  methods: {
    assignTransactions() {
      this.transactions = Object.assign([], this.order.transactions
        .filter(it => it.approved === 1 && it.transactionType !== PaymentTypes.Refund && !it.status.toLowerCase().endsWith('refunded'))
        .map(it => {
          return {
            id: it.id,
            amount: it.amount,
            checked: false,
            refund: it.amount,
          };
        }));
    },
    handleModalShow() {
      // update the transactions
      this.assignTransactions();

      // assign the transactions to the first complaint
      this.complaint.transactions = structuredClone(this.transactions);
    },
    addAnotherComplaint() {
      this.complaints.push({
        category: '',
        comment: '',
        order: null,
        recipes: [],
        reportedBy: null,
        resolution: '',
        resolutionAmount: 0,
        complaint_id: null,
        transactions: structuredClone(this.transactions),
      });

      this.complaintsIndex++;
      this.selectedIssueTitle = '';
    },
    async getIngredientList(id) {
      this.loading = true;
      this.ingredients = [];

      try {
        const {item} = await recipes.getById(id, {params: {with_ingredients: 1}});
        const uniqueIngredients = [];

        item.ingredients.filter(data => parseInt(data.people) === this.order.numberOfPeople).forEach(data => {
          if (!uniqueIngredients.find(val => val.id === data.id)) {
            uniqueIngredients.push(data);
          }
        });

        this.ingredients = uniqueIngredients;
        this.recipeId = id;
        this.selectedIngredients = ((this.complaint.recipes.find(item => item.id === id) || {}).ingredients || []).map(item => item.id) || [];
      }
      catch (ex) {
        console.error(ex);
      }
      finally {
        this.loading = false;
      }
    },
    handleResolutionChange() {
      // clear the checked transactions checked if resolution is not refund
      if (this.complaint.resolution !== 'refund') {
        this.complaint.transactions.forEach(transaction => transaction.checked = false);
        this.complaint.markOrderRefunded = false;
      }
    },
    async openIngredientsModal(id) {
      await this.getIngredientList(id);
      this.ingredientsModal = true;
    },
    selectComplaint(issue, category) {
      this.selectedIssueTitle = issue.title;
      Object.assign(this.complaint, {
        complaint_id: issue.id,
        reason: issue.title,
        category,
      });
    },
    removeComplaint() {
      this.selectedIssueTitle = '';
      this.complaintsIndex--;
      this.complaints.splice(this.complaintsIndex + 1, 1);
    },
    resetComplaints() {
      this.selectedIssueTitle = '';
      this.complaintsIndex = 0;
      this.invalid = false;
      this.invalidMessage = '';

      this.complaints = [{
        category: '',
        comment: '',
        recipes: [],
        reportedBy: null,
        resolution: '',
        resolutionAmount: 0,
        complaint_id: null,
        transactions: structuredClone(this.transactions),
        markOrderRefunded: false,
      }];
    },
    async submit() {
      try {
      // needs to validate also if there are more than one complaints
        const errorIndex = [];

        this.complaints.forEach((item, index) => {
          if (['Quality issue', 'Missing items'].includes(item.category) && (!item.recipes.length || !item.recipes.every(item => item.ingredients.length))) { errorIndex.push(index); }

          if (['Recipe issues'].includes(item.category) && !item.recipes.length) { errorIndex.push(index); }

          if (!item.category || !item.resolution || !item.complaint_id) { errorIndex.push(index); }

          if (item.resolution === 'storeCredit' && !item.resolutionAmount) { errorIndex.push(index); }

          if (item.resolution === 'refund' && !item.transactions.some(transaction => transaction.checked)) { errorIndex.push(index); }
        });

        if (errorIndex.length) {
          this.invalidMessage = `Some values are still missing or invalid on complaints ${errorIndex.map(i => i + 1).join(', ')}. Please check them!`;
          this.invalid = true;
          return;
        }

        const complaintsPayload = this.complaints.flatMap(item => {
        // if resolution is refund, calculate the total refund amount
          if (item.resolution === 'refund') {
            item.resolutionAmount = item.transactions
              .filter(transaction => transaction.checked)
              .reduce((acc, transaction) => acc + Number(transaction.refund), 0);
          }

          const payload = {
            category: item.category,
            comment: item.comment,
            order: this.order.id,
            reportedBy: this.user.id,
            resolution: item.resolution,
            resolutionAmount: Number(item.resolutionAmount),
            complaint_id: item.complaint_id,
            reason: item.reason,
            transactions: item.transactions,
            markOrderRefunded: item.markOrderRefunded,
          };

          if (this.cid) {
            payload.id = this.cid;
          }

          if (item.recipes.length) {
            return item.recipes.flatMap((recipe, index) => {
              if (recipe.ingredients && recipe.ingredients.length) {
                return recipe.ingredients.flatMap((ingredient, iIndex) => {
                  item.resolutionAmount = !iIndex ? Number(item.resolutionAmount) : 0;

                  return Object.assign({}, payload, {
                    main_ingredient: parseInt(ingredient.id),
                    recipe: recipe.id,
                    resolutionAmount: !index && !iIndex ? Number(item.resolutionAmount) : 0,
                  });
                });
              }

              return Object.assign({}, payload, {
                recipe: recipe.id,
                resolutionAmount: !index ? Number(item.resolutionAmount) : 0,
              });
            });
          }

          return payload;
        });

        if (!this.cid) {
          const actionPromises = [];

          complaintsPayload.filter(item => item.resolutionAmount > 0).forEach(item => {
            if (item.resolution === 'storeCredit') {
              const storeCreditParams = {
                amount: item.resolutionAmount,
                customer: this.order.item.customer.id,
                reason: item.reason,
                used: false,
              };

              actionPromises.push(storeCredit.saveOrUpdate(storeCreditParams));
            }

            if (item.resolution === 'refund') {
              const refundParams = {
                mark_as_refunded: item.markOrderRefunded,
                refunds: item.transactions
                  .filter(transaction => transaction.checked)
                  .map(transaction => ({
                    payment_id: transaction.id,
                    amount: transaction.refund,
                  })),
              };

              actionPromises.push(payments.refund(refundParams));
            }
          });

          await Promise.all(actionPromises);
        }

        const complaintPromises = [];
        complaintsPayload.forEach(item => {
          // remove unnecessary properties for submission
          delete item.markOrderRefunded;
          delete item.transactions;

          complaintPromises.push(orderComplaints.saveOrUpdate(item));
        });
        await Promise.all(complaintPromises);

        this.resetComplaints();
        this.$emit('success');
        this.safeShow = false;
      }
      catch (error) {
        captureException(error);

        if (error.response?.data.message) {
          this.$toasted.error(error.response.data.message, {duration: 5000});
        }
      }
      finally {
        this.loading = false;
      }
    },
    updateIngredients() {
      const recipe = this.complaint.recipes.find(item => item.id === this.recipeId);
      if (recipe) {
        recipe.ingredients = this.selectedIngredients.map(item => this.ingredients.find(data => data.id === item));
      }
      else if (this.cid) {
        this.complaint.recipes = [{
          id: this.recipeId,
          ingredients: this.selectedIngredients.map(item => this.ingredients.find(data => data.id === item)),
          number: this.selectedRecipe.number,
        }];
      }
      else {
        this.complaint.recipes.push({
          id: this.recipeId,
          ingredients: this.selectedIngredients.map(item => this.ingredients.find(data => data.id === item)),
          number: this.selectedRecipe.number,
        });
      }

      this.ingredientsModal = false;
      this.selectedIngredients = [];
      this.recipeId = null;
    },
  },
};

</script>

<style>
.modal-footer {
  justify-content: space-between;
}

.complaint-issue-dropdown .dropdown-menu {
  max-height: 300px;
  min-width: 370px;
  overflow-y: scroll;
}

.complaint-issue-dropdown .dropdown-header {
  font-weight: bold;
}

.complaint-issue-dropdown .dropdown-item {
  color: black;
}

.complaint-issue-dropdown .b-dropdown>.btn-secondary:disabled {
  color: #868e96;
  background-color: #e9ecef;
  opacity: 1;
}

.complaint-issue-dropdown .b-dropdown>.btn {
  width: 100%;
}

.complaint-refund-table.table td {
  padding: 4px;
}

.complaint-refund-table.table tr.table-success td {
  border-color: #c4c4c4;
}
</style>
