<template>

  <div>
    <div class="card-body" :class="{'was-validated': $v.value.$dirty}">
      <div class="container">
        <ul v-if="value.replenishments.length" class="nav nav-tabs">
          <li
            v-for="(replenishment, index) in $v.value.replenishments.$each.$iter"
            :key="index"
            class="nav-item">
            <div class="d-flex justify-space-between">
              <a
                :class="{'active': tab === index}"
                class="nav-link"
                @click="tab = index"><i v-if="replenishment.$error" class="mr-1 text-danger fe fe-alert-circle"></i>{{(replenishment.supplier.$model || {}).name || `New ${parseInt(index) + 1}`}} {{replenishment.is_preferred_supplier.$model ? '(preferred)' : ''}}</a>
              <button
                v-if="value.replenishments.length > 1 && edit"
                class="btn btn-link"
                @click.prevent="removeReplenishment(index)">
                <i class="fe fe-x-circle"></i>
              </button>
            </div>
          </li>
          <li v-if="edit" class="nav-item">
            <a class="nav-link text-center" @click="addNewReplenishment">Add <i class="fe fe-plus w-100"></i></a>
          </li>
        </ul>
      </div>
      <div class="tab-content">
        <div
          v-for="(replenishment, index) in $v.value.replenishments.$each.$iter"
          :key="index"
          :class="tab === index ? 'show active' : ''"
          class="tab-pane fade">
          <template v-if="edit">
            <div class="mt-4">
              <div class="form-group row d-flex">
                <label class="col-sm-3 col-form-label text-right font-weight-bold">Supplier <span class="text-danger">*</span></label>
                <div class="col-sm-7">
                  <autocomplete
                    v-model="value.replenishments[parseInt(index)].supplier"
                    :list.sync="suppliers"
                    :nextPage="nextSuppliers"
                    :class="{'is-invalid': replenishment.supplier.$error}"
                    label="name"
                    entity="supplier"></autocomplete>
                  <template v-if="replenishment.supplier.$dirty">
                    <div v-if="!replenishment.supplier.required" class="invalid-feedback d-block">Supplier is required</div>
                  </template>
                </div>
              </div>
              <div class="form-group row d-flex">
                <label class="col-sm-3 col-form-label text-right font-weight-bold">Supplier SKU code <span class="text-danger">*</span></label>
                <div class="col-sm-7">
                  <input
                    v-model.trim="replenishment.sku_code.$model"
                    :class="{'is-invalid': replenishment.sku_code.$error}"
                    type="text"
                    class="form-control"/>
                  <template v-if="replenishment.sku_code.$dirty">
                    <div v-if="!replenishment.sku_code.required" class="invalid-feedback d-block">Supplier SKU code is required</div>
                    <div v-if="!replenishment.sku_code.apiError" class="invalid-feedback d-block">Supplier SKU code has already been taken</div>
                  </template>
                </div>
              </div>
              <div class="form-group row d-flex">
                <label class="col-sm-3 col-form-label text-right font-weight-bold">Set as preferred supplier <span class="text-danger">*</span></label>
                <div class="col-sm-7">
                  <label class="custom-switch mt-2">
                    <input
                      :checked="replenishment.is_preferred_supplier.$model"
                      type="checkbox"
                      class="custom-switch-input"
                      @change="setPreferredSupplier(replenishment, index)">
                    <span class="custom-switch-indicator"></span>
                    <span class="custom-switch-description"></span>
                  </label>
                  <template v-if="replenishment.is_preferred_supplier.$dirty">
                    <div v-if="!replenishment.is_preferred_supplier.checked" class="invalid-feedback d-block">You must choose at least one preferred supplier</div>
                  </template>
                </div>
              </div>
              <div class="form-group row d-flex">
                <label class="col-sm-3 col-form-label text-right font-weight-bold">Delivery day <span class="text-danger">*</span></label>
                <div class="col-sm-7">
                  <label
                    v-for="date in weekDays"
                    :key="date.id"
                    class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      :checked="!!replenishment.delivery_days.$model.find(item => item.id === date.id)"
                      type="checkbox"
                      @change="toggleArray(replenishment.delivery_days.$model, date, (array, selected) => array.id === selected.id)">
                    <span class="form-check-label">{{date.name}}</span>
                  </label>
                  <template v-if="replenishment.delivery_days.$dirty">
                    <div v-if="!replenishment.delivery_days.required" class="invalid-feedback d-block">Delivery day is required</div>
                  </template>
                </div>
              </div>
              <div class="form-group row d-flex">
                <label class="col-sm-3 col-form-label text-right font-weight-bold">Order day <span class="text-danger">*</span></label>
                <div class="col-sm-7">
                  <label
                    v-for="date in weekDays"
                    :key="date.id"
                    class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      :checked="!!replenishment.ordering_days.$model.find(item => item.id === date.id)"
                      type="checkbox"
                      @change="toggleArray(replenishment.ordering_days.$model, date, (array, selected) => array.id === selected.id)">
                    <span class="form-check-label">{{date.name}}</span>
                  </label>
                  <template v-if="replenishment.ordering_days.$dirty">
                    <div v-if="!replenishment.ordering_days.required" class="invalid-feedback d-block">Order day is required</div>
                  </template>
                </div>
              </div>
              <div class="form-group row d-flex">
                <label class="col-sm-3 col-form-label text-right font-weight-bold">Replenishment Type <span class="text-danger">*</span></label>
                <div class="col-sm-7">
                  <select
                    v-model="value.replenishments[parseInt(index)].status"
                    :class="{'is-invalid': replenishment.status.$error }"
                    class="form-control custom-select"
                    disabled
                  >
                    <option :value="null">Select Status</option>
                    <option
                      v-for="(replenishmentStatusName, replenishmentStatuskey) in replenishmentStatuses"
                      :key="replenishmentStatuskey"
                      :value="replenishmentStatuskey"
                    >
                      {{replenishmentStatusName}}
                    </option>
                  </select>
                  <template v-if="replenishment.status.$dirty">
                    <div v-if="!replenishment.status.required" class="invalid-feedback d-block">Replenishment Type is required</div>
                  </template>
                </div>
              </div>
              <div class="form-group row d-flex">
                <label class="col-sm-3 col-form-label text-right font-weight-bold">Lead time <span class="text-danger">*</span></label>
                <div class="col-sm-3">
                  <div class="input-group">
                    <input
                      v-model="value.replenishments[parseInt(index)].lead_time"
                      :class="{'is-invalid': replenishment.lead_time.$error}"
                      class="form-control"
                      type="number"
                      min="1"
                      step="any">
                    <div class="input-group-append">
                      <div class="input-group-text">hours</div>
                    </div>
                  </div>
                  <template v-if="replenishment.lead_time.$dirty">
                    <div v-if="!replenishment.lead_time.required" class="invalid-feedback d-block">Lead time is required</div>
                    <div v-if="!replenishment.lead_time.minValue" class="invalid-feedback d-block">Lead time should be at least 1</div>
                  </template>
                </div>
              </div>
              <div class="form-group row d-flex">
                <label class="col-sm-3 col-form-label text-right font-weight-bold">Production yield <i v-b-tooltip="YIELD" class="fe fe-info"></i> <span class="text-danger">*</span></label>
                <div class="col-sm-3">
                  <div class="input-group">
                    <input
                      v-model="value.replenishments[parseInt(index)].production_yield"
                      :class="{'is-invalid': replenishment.production_yield.$error}"
                      :disabled="isPurchasableAndPackable"
                      class="form-control"
                      type="number"
                      step="any"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">%</div>
                    </div>
                  </div>
                  <template v-if="replenishment.production_yield.$dirty">
                    <div v-if="!replenishment.production_yield.required" class="invalid-feedback d-block">Production yield is required</div>
                    <div v-if="!replenishment.production_yield.maxValue" class="invalid-feedback d-block">Production yield must not be greater than 100</div>
                  </template>
                </div>
              </div>
              <div class="form-group row d-flex">
                <label class="col-sm-3 col-form-label text-right font-weight-bold">Ordering unit of measure <span class="text-danger">*</span></label>
                <div class="col-sm-3">
                  <select
                    v-model="value.replenishments[parseInt(index)].ordering_uom"
                    :class="{'is-invalid': replenishment.ordering_uom.$error}"
                    class="form-control custom-select">
                    <option :value="null">Select ordering unit of measure</option>
                    <option
                      v-for="unit in uomOrderingUnits"
                      :key="unit.id"
                      :value="unit">
                      {{unit.name}}
                    </option>
                  </select>
                  <template v-if="replenishment.ordering_uom.$dirty">
                    <div v-if="!replenishment.ordering_uom.required" class="invalid-feedback d-block">Ordering unit of measure is required</div>
                  </template>
                </div>
              </div>
              <div class="form-group row d-flex">
                <label class="col-sm-3 col-form-label text-right font-weight-bold">Minimum order quantity per {{(replenishment.ordering_uom.$model || {}).name || 'ordering unit'}}<span class="text-danger">*</span></label>
                <div class="col-sm-3">
                  <input
                    v-model="value.replenishments[parseInt(index)].moq"
                    :class="{'is-invalid': replenishment.moq.$error}"
                    class="form-control"
                    type="number"
                    step="any">
                  <template v-if="replenishment.moq.$dirty">
                    <div v-if="!replenishment.moq.required" class="invalid-feedback d-block">Minimum order quantity is required</div>
                  </template>
                </div>
              </div>
              <div class="form-group row d-flex">
                <label class="col-sm-3 col-form-label text-right font-weight-bold">Pack size <i v-b-tooltip="PACK_SIZE" class="fe fe-info"></i> <span class="text-danger">*</span></label>
                <div class="col-sm-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">1 by </div>
                    </div>
                    <input
                      v-model="value.replenishments[parseInt(index)].pack_size_per_oum"
                      :class="{'is-invalid': replenishment.pack_size_per_oum.$error}"
                      class="form-control"
                      type="number"
                      step="any"
                      @input="() => updateReplenishmentNetWeight(replenishment)"
                    >
                  </div>
                  <template v-if="replenishment.pack_size_per_oum.$dirty">
                    <div v-if="!replenishment.pack_size_per_oum.required" class="invalid-feedback d-block">Pack size is required</div>
                  </template>
                </div>
              </div>

              <div class="form-group row d-flex">
                <label
                  class="col-sm-3 col-form-label text-right font-weight-bold">Net weight per {{(replenishment.ordering_uom.$model || {}).name || 'ordering unit'}} <i v-b-tooltip="NET_WEIGHT_PER_PIECE" class="fe fe-info"></i> <span class="text-danger">*</span></label>
                <div class="col-sm-3">
                  <div class="input-group">
                    <input
                      v-model="value.replenishments[parseInt(index)].net_weight"
                      class="form-control"
                      type="number"
                      step="any"
                      disabled
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        {{(value.base_unit_uom || {}).name || 'net weight unit'}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row d-flex">
                <label class="col-sm-3 col-form-label text-right font-weight-bold">Cost price per {{(replenishment.ordering_uom.$model || {}).name || 'ordering unit'}}<span class="text-danger">*</span></label>
                <div class="col-sm-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">AED</div>
                    </div>
                    <input
                      v-model="value.replenishments[parseInt(index)].cost_price"
                      :class="{'is-invalid': replenishment.cost_price.$error}"
                      class="form-control"
                      type="number"
                      step="any"
                      :disabled="value.replenishments[parseInt(index)].disable_cost_price"
                    />
                  </div>
                  <template v-if="replenishment.cost_price.$dirty">
                    <div v-if="!replenishment.cost_price.required" class="invalid-feedback d-block">Cost price is required</div>
                  </template>
                </div>
              </div>
            </div>
          </template>

          <template v-else>
            <div class="mt-4">
              <form-description label="Supplier" :value="(replenishment.supplier.$model || {}).name"/>
              <form-description label="Supplier SKU code" :value="replenishment.sku_code.$model"/>
              <form-description label="Delivery day" :value="(replenishment.delivery_days.$model || {}).reduce((accumulated, day) => `${accumulated} ${day.name}`, '')"/>
              <form-description label="Order day" :value="(replenishment.ordering_days.$model || {}).reduce((accumulated, day) => `${accumulated} ${day.name}`, '')"/>
              <form-description label="Replenishment Type" :value="replenishmentStatuses[replenishment.status.$model]"/>
              <form-description label="Lead time" :value="`${numeral(replenishment.lead_time.$model).format('0,0[.]00')} hour${replenishment.lead_time > 1 ? 's' : ''}`"/>

              <form-description>
                <label class="text-right font-weight-bold col-sm-5 d-inline-flex justify-content-end align-items-center gap-4">
                  Production yield <i v-b-tooltip="YIELD" class="fe fe-info"></i>:
                </label>
                <div class="col-sm-7">{{`${numeral(replenishment.production_yield.$model).format('0,0[.]00')}%`}}</div>
              </form-description>

              <form-description label="Ordering unit of measure" :value="(replenishment.ordering_uom.$model || {}).name"/>
              <form-description :label="`Minimum order quantity per ${(replenishment.ordering_uom.$model || {}).name}`" :value="replenishment.moq.$model"/>

              <form-description>
                <label class="text-right font-weight-bold col-sm-5 d-inline-flex justify-content-end align-items-center gap-4">
                  Pack size <i v-b-tooltip="PACK_SIZE" class="fe fe-info"></i> :
                </label>
                <div class="col-sm-7">{{`1 by ${numeral(replenishment.pack_size_per_oum.$model).format('0,0[.]00')}`}}</div>
              </form-description>

              <form-description :label="`Net weight per ${(replenishment.ordering_uom.$model || {}).name}`" :value="`${numeral(replenishment.net_weight.$model).format('0,0[.]00')} ${(value.base_unit_uom || {}).name}`"/>

              <div class="row d-flex align-items-start">
                <label class="text-right font-weight-bold col-sm-5 mb-0">
                  Cost price per {{(replenishment.ordering_uom.$model || {}).name}} :
                </label>
                <div class="col-sm-7 d-flex align-items-start flex-column">
                  <span>AED {{numeral(replenishment.cost_price.$model).format('0,0.00')}}</span>
                  <price-change-request-modal
                    v-if="priceChangeRequest"
                    :replenishment="value.replenishments[parseInt(index)]"
                    @submit="$emit('reset')"/>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div v-if="!value.replenishments.length" class="text-center">
        <button
          v-if="edit"
          class="btn btn-primary"
          @click.prevent="addNewReplenishment">
          Add new replenishment
        </button>
        <div v-else class="text-center">No replenishments available</div>
      </div>

      <template v-if="$v.$anyDirty && $v.$anyError">
        <div v-if="$v.$anyError" class="invalid-feedback d-block">Replenishment is required</div>
      </template>
    </div>
    <div v-if="edit" class="card-footer d-flex">
      <button class="btn btn-primary ml-auto" @click.prevent="validate">Apply</button>
    </div>
  </div>

</template>

<script>

import Autocomplete from '@/components/Autocomplete';
import FormDescription from '@/components/FormDescription';
import {suppliers, weekDays} from '@/services';
import {maxValue, minLength, minValue, required} from 'vuelidate/lib/validators';
import PriceChangeRequestModal from '../modals/PriceChangeRequestModal.vue';

const YIELD = 'Anything we portion and has spillage/waste has a yield <100%. Generally, all items with a recipe UoM of weight will have a yield <100% and articles with a recipe UoM of quantity will have a yield of 100%';
const PACK_SIZE = 'This is the ordering unit of measure that we order, that the supplier delivers in and what we pay for. The lowest level is piece (1 x 1) and things go up from there. If the pack size is larger than 1 piece, we need to make sure the piece quantity is reflected in the case (or other) pack size (ex. 1 x 24)';
const NET_WEIGHT_PER_PIECE = 'Net weight per OUM is equals to the article net weight x pack size';

export default {
  components: {
    PriceChangeRequestModal,
    Autocomplete,
    FormDescription,
  },
  props: {
    edit: {
      default: true,
      type: Boolean,
    },
    errors: {
      default: () => {},
      type: Object,
    },
    old: {
      type: Object,
    },
    uom: {
      required: true,
      type: Array,
    },
    value: {
      required: true,
      type: Object,
    },
    priceChangeRequest: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      YIELD,
      PACK_SIZE,
      NET_WEIGHT_PER_PIECE,
      suppliers: [],
      tab: '0',
      weekDays: [],
      replenishmentStatuses: {
        roster_ordering: 'Roster Ordering',
        auto_replenishment: 'Auto Replenishment',
      },
    };
  },
  computed: {

    isPurchasableAndPackable() {
      return this.value?.material_type?.is_purchasable && this.value?.material_type?.is_recipe_material;
    },
    uomOrderingUnits() {
      return this.uom.filter(value => value.is_for_oum);
    },
  },
  created() {
    suppliers.getByParameters({column: 'name', direction: 'asc'}).then(result => this.suppliers = result);
    weekDays.getByParameters({column: 'name', direction: 'asc'}).then(result => this.weekDays = result.items);
  },
  methods: {
    addNewReplenishment() {
      this.value.replenishments.push({
        delivery_days: [],
        is_preferred_supplier: false,
        net_weight: null,
        ordering_days: [],
        status: 'roster_ordering',
        production_yield: this.isPurchasableAndPackable ? 100 : null,
        quickbook_name: (`${this.value?.base_unit || ''} ${this.value?.base_unit_uom?.name || ''} ${this.value.brand?.name || ''} ${this.value?.ingredient?.name || ''}`).trim(),
        supplier: null,
        sku_code: '',
      });

      this.tab = (this.value.replenishments.length - 1).toString();
    },
    async nextSuppliers(page, query) {
      const params = {

        column: 'name',
        direction: 'asc',
        page,
      };

      if (query.length) params.query = query;

      const result = await suppliers.getByParameters(params);

      return result;
    },
    removeReplenishment(index) {
      this.value.replenishments.splice(parseInt(index), 1);
    },
    setPreferredSupplier(value, index) {
      // reset preferred values
      this.value.replenishments.map((item, key) => {
        return Object.assign(item, {is_preferred_supplier: key === parseInt(index)});
      });

      value.is_preferred_supplier.$touch();
    },
    updateReplenishmentNetWeight(replenishment) {
      replenishment.net_weight.$model = replenishment.pack_size_per_oum.$model * this.value.base_unit;
    },
    validate() {
      this.$v.value.$touch();

      if (!this.$v.value.$invalid) { this.$emit('valid'); }
    },
  },
  validations: {
    value: {
      replenishments: {
        $each: {
          cost_price: {
            required,
          },
          delivery_days: {
            required: value => value.length,
          },
          is_preferred_supplier: {
            checked: function(value) {
              return this.value.replenishments.some(item => item.is_preferred_supplier === true);
            },
          },
          lead_time: {
            required,
            minValue: minValue(1),
          },
          status: {
            required,
          },
          moq: {
            required,
          },
          net_weight: {
            required,
          },
          ordering_days: {
            required,
          },
          ordering_uom: {
            required,
          },
          pack_size_per_oum: {
            required,
          },
          production_yield: {
            maxValue: maxValue(100),
            required,
          },
          quickbook_name: {
            apiError: function(value) {
              return !(Object.keys(this.errors || []).find(error => error.includes('quickbook_name')) && (this.old?.replenishments || []).find(item => item.quickbook_name === value));
            },
            required,
          },
          supplier: {
            required,
          },
          sku_code: {
            apiError: function(value) {
              return !(Object.keys(this.errors || []).find(error => error.includes('sku_code')) && (this.old?.replenishments || []).find(item => item.sku_code === value));
            },
            required,
          },
        },
        minLength: minLength(1),
        required,
      },
    },
  },
};
</script>
