<template>

  <div>
    <div class="card-body">
      <template v-if="edit">
        <div class="form-inline d-flex gap-8 mb-4">
          <label>Has seasonality</label>
          <div>
            <label class="custom-switch mt-2">
              <input
                type="checkbox"
                class="custom-switch-input"
                :checked="value.has_seasonality"
                @change="value.has_seasonality = !value.has_seasonality">
              <span class="custom-switch-indicator"></span>
              <span class="custom-switch-description"></span>
            </label>
          </div>
        </div>
        <template v-if="value.has_seasonality">
          <div class="form-inline gap-8">
            <label for="season">Season:</label>
            <select
              v-model="season"
              class="form-control"
              :class="{'is-invalid': $v.season.$error}"
              id="season"
              @change="handleSeasonChange">
              <option :value="null">Choose season</option>
              <option
                v-for="season in SEASONS"
                :key="season.label"
                :value="season">
                {{season.label}}
              </option>
            </select>

            <template v-if="$v.season.$dirty">
              <div v-if="!$v.season.required" class="invalid-feedback d-block">Season is required</div>
            </template>
          </div>
        </template>
      </template>
      <template v-else>
        <form-description label="Has seasonality" :value="value.has_seasonality ? 'Yes' : 'No'"/>
        <form-description v-if="value.has_seasonality">
          <label class="text-right font-weight-bold col-sm-5">Season:</label>
          <div class="col-sm-7">
            {{season.label}}
          </div>
        </form-description>
      </template>
    </div>

    <div v-if="edit" class="card-footer d-flex">
      <button class="btn btn-primary ml-auto" @click.prevent="validate">Apply</button>
    </div>
  </div>

</template>

<script>

import FormDescription from '@/components/FormDescription';
import {requiredIf, maxValue} from 'vuelidate/lib/validators';

// TODO: update season start day and end day
const SEASONS = [
  {label: 'Summer', season_start_day: 1, season_start_month: 5, season_end_day: 31, season_end_month: 9},
  {label: 'Winter', season_start_day: 1, season_start_month: 10, season_end_day: 30, season_end_month: 4},
];

export default {

  components: {
    FormDescription,
  },
  props: {

    edit: {

      default: false,
      type: Boolean,
    },
    value: {

      required: true,
      type: Object,
    },
  },
  data() {
    return {

      SEASONS,
      season: {},
    };
  },
  mounted() {
    if (this.value.has_seasonality) {
      this.season = this.SEASONS.find(season => {
        return season.season_start_month === this.value.season_start_month;
      }) || {};
    }
  },
  methods: {

    validate() {
      this.$v.value.$touch();
      this.$v.season.$touch();

      if (!this.$v.value.$invalid) { this.$emit('valid'); }
    },
    handleSeasonChange() {
      this.value.season_start_day = this.season?.season_start_day;
      this.value.season_start_month = this.season?.season_start_month;
      this.value.season_end_day = this.season?.season_end_day;
      this.value.season_end_month = this.season?.season_end_month;
    },
  },
  validations: {

    value: {
      season_end_day: {
        maxValue: maxValue(31),
        required: requiredIf(function(value) {
          return this.value.has_seasonality;
        }),
      },
      season_end_month: {
        required: requiredIf(function(value) {
          return this.value.has_seasonality;
        }),
      },
      season_start_day: {
        maxValue: maxValue(31),
        required: requiredIf(function(value) {
          return this.value.has_seasonality;
        }),
      },
      season_start_month: {
        required: requiredIf(function(value) {
          return this.value.has_seasonality;
        }),
      },
    },
    season: {
      required: requiredIf(function(value) {
        return this.value.has_seasonality;
      }),
    },
  },
};
</script>
