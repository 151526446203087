<template>

  <div>
    <div class="card-body">
      <table class="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Portions</th>
            <th>Recipe UoM</th>
            <th>Article name</th>
            <th>Packaging article</th>
            <th>Don't portion</th>
            <th>Yield</th>
            <th v-if="edit"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(article, index) in value.children" :key="index">
            <td>{{article.base_unit || 'N/A'}}</td>
            <td>{{article.base_unit_uom ? article.base_unit_uom.name : 'N/A'}}</td>
            <td>
              <a :href="`/articles/${value.children[index].id}`">
                {{article.name}}
              </a>
            </td>
            <td>{{article.packaging_article ? article.packaging_article.name : 'N/A'}}</td>
            <td align="center" class="vertical-align-middle">
              <div class="d-flex align-items-center justify-content-center">
                <input
                  v-if="edit"
                  v-model="article.is_portioned"
                  type="checkbox"
                  class="custom-checkbox td-checkbox"
                />
                <p v-else>{{article.is_portioned ? 'Y' : 'N'}}</p>
              </div>
            </td>
            <td align="center">
              <div class="d-flex align-items-center justify-content-center">
                <input
                  v-if="edit"
                  v-model="article.yield"
                  type="number"
                  class="form-control"
                />
                <p v-else>{{article.yield || 100}}</p>
              </div>
            </td>
            <td v-if="edit">
              <div class="d-flex gap-12 ml-4">
                <a :href="`/articles/${value.children[index].id}`">
                  <i
                    v-b-tooltip="'Edit'"
                    class="dropdown-icon fe fe-edit cursor-pointer">
                  </i>
                </a>
                <a :href="`/articles/new?duplicate=${value.children[index].id}`">
                  <i
                    v-b-tooltip="'Duplicate'"
                    class="dropdown-icon fe fe-copy cursor-pointer">
                  </i>
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div v-if="edit" class="d-flex justify-content-center">
        <a
          :href="`/articles/new?parent=${value.id}`"
          class="btn btn-outline-primary">
          Add packable article
        </a>
      </div>
    </div>

    <div v-if="edit" class="card-footer d-flex">
      <button class="btn btn-primary ml-auto" @click.prevent="$emit('valid')">Apply</button>
    </div>
  </div>

</template>

<script>

export default {
  props: {

    edit: {

      default: false,
      type: Boolean,
    },
    value: {

      required: true,
      type: Object,
    },
  },
};
</script>

<style scoped>
.td-checkbox {
  width: 16px;
  height: 16px;
}
</style>
