<template>
  <div class="px-6">
    <edit-header
      entity="purchase order"
      :error.sync="error"
      :invalid.sync="invalid"
      :invalid-message="invalidMessage"
      :submitted.sync="submitted"
      :success-message="successMessage"
      :isNew="true"/>
    <div class="row">
      <div class="col-12">
        <div class="bg-white">
          <div class="card border-0 shadow-none">
            <div class="card-header">
              <div class="card-title">
                Roster purchase order
              </div>
              <div class="card-options">
                <a
                  v-if="!edit"
                  href="#"
                  class="btn btn-link"
                  @click.prevent="edit = true">Edit filter</a>
              </div>
            </div>
            <div class="card-body">
              <dimmer :active="searching">
                <purchase-order-filter
                  ref="filter"
                  :edit="edit"
                  :filter.sync="filter"
                  @valid="data => applyFilter(data)"
                  @export="data => exportForecast(data)"
                />
              </dimmer>
            </div>
          </div>
          <div class="mt-4">
            <form
              v-if="!edit"
              ref="form"
              class="validation bg-light"
              novalidate
              @submit.prevent="() => submitPurchaseOrder(false)">
              <div class="row">
                <div class="col">
                  <div class="card border-0">
                    <div class="card-header border-0 pb-4">
                      <div class="card-title">
                        Showing {{filteredResults.length}} articles
                        <div class="small text-muted">Selected {{selectedArticles.length}} articles</div>
                      </div>
                      <div class="card-options gap-10">
                        <table-filters
                          class="d-flex align-items-center"
                          :options="tableFilterList"
                          :filters.sync="resultFilters"
                          :items="items"/>

                        <button class="btn btn-primary" @click.prevent="exportTable">Export</button>
                        <button class="btn btn-primary" @click.prevent="() => submitPurchaseOrder(false)">Submit</button>
                      </div>
                    </div>
                    <div class="table-responsive table-sm mb-0" style="max-height: 35rem; overflow-y: auto;">
                      <table class="table table-outline table-bordered table-striped table-hover table-vcenter card-table sticky-table dataTables_wrapper">
                        <thead class="thead-light leading-none text-center">
                          <tr>
                            <!-- TODO: this is for the packable breakdown -->
                            <!-- <th class="w-1 border-right-1"></th> -->
                            <th
                              class="w-1 border-right-0 pl-4 pr-4"
                              :class="column === 'isSelected' ? `sorting_${sort}` : 'sorting'"
                              @click="() => sortColumn('isSelected', 'string')">
                              <input
                                class="align-middle"
                                type="checkbox"
                                @input="toggleAllCheckbox($event.target.checked)">
                            </th>
                            <th
                              class="border-left-0 font-weight-bold text-left"
                              :class="column === 'article_name' ? `sorting_${sort}` : 'sorting'"
                              @click="() => sortColumn('article_name', 'string')">
                              Article
                            </th>
                            <th class="font-weight-bold">Supplier</th>
                            <th
                              v-b-tooltip.hover
                              title="Required Qty"
                              class="cursor-pointer text-nowrap font-weight-bold">
                              Required Qty
                            </th>
                            <th
                              v-b-tooltip.hover
                              title="Stock on Hand in Each"
                              class="cursor-pointer text-nowrap font-weight-bold">
                              SOH
                            </th>
                            <th
                              v-if="filter.purchaseType === 'actual'"
                              v-b-tooltip.hover
                              title="Suggested top-up quantity"
                              class="cursor-pointer text-nowrap font-weight-bold">
                              STQ
                            </th>
                            <th
                              v-b-tooltip.hover
                              title="Total quantity that still needed to be order"
                              class="w-1 font-weight-bold">
                              Quantity to Order
                            </th>
                            <th
                              v-b-tooltip.hover
                              title="If you want to add or subtract from the quantity to order"
                              class="w-1 font-weight-bold"
                              style="min-width: 150px;">
                              Procurement Adjusment
                            </th>
                            <th
                              v-b-tooltip.hover
                              title="This is the quantity we will order to the supplier"
                              class="cursor-pointer text-nowrap font-weight-bold bg-success text-white pr-4"
                              :class="column === 'decided_order_quantity' ? `sorting_${sort}` : 'sorting'"
                              @click="() => sortColumn('decided_order_quantity')">
                              Final order
                            </th>
                            <th
                              v-b-tooltip.hover
                              title="Stock on order"
                              class="text-nowrap font-weight-bold">
                              SOO
                            </th>
                            <th class="font-weight-bold">Delivery date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="article in filteredResults">
                            <tr
                              :key="`${article.article_id}-main`"
                              :class="{'table-success': article.isSelected}">
                              <!-- this is for the packable breakdown -->
                              <!-- <td class="border-right-0 cursor-pointer" @click.prevent="() => article.collapsed = !article.collapsed">
                                <i class="fe" :class="article.collapsed ? 'fe-chevron-right' : 'fe-chevron-down'"></i>
                              </td> -->
                              <td class="border-right-0 pl-4">
                                <input
                                  v-model="article.isSelected"
                                  type="checkbox"
                                  class="align-middle">
                              </td>
                              <td class="border-left-0">
                                <div class="d-flex flex-column" style="min-width: 200px;">
                                  <strong>{{article.article_name}} <router-link
                                    :to="`/articles/${article.article_id}`"
                                    target="_blank"
                                    class="fe fe-link text-blue cursor-pointer"
                                    @click="editSupplier(article)"/>
                                  </strong>
                                  <div class="d-flex gap-2">
                                    <span v-if="article.is_moving_stock" class="badge badge-default">Moving stock</span>
                                    <span class="badge badge-default">{{article.pack_size}}</span>
                                    <span class="badge badge-default">{{article.replenishment_lead_time}} hrs. lead time</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div style="min-width: 140px;">
                                  <template v-if="!article.edit_supplier">
                                    <span>
                                      {{article.supplier_name}}
                                      <i v-if="article.loading" class="fe fe-loader spin-icon"/>
                                      <i
                                        v-else
                                        class="fe fe-edit text-blue cursor-pointer"
                                        @click="editSupplier(article)"/>
                                    </span>
                                  </template>
                                  <select
                                    v-else
                                    v-model="article.supplier_id"
                                    class="form-control form-control-sm custom-select"
                                    @change="onSupplierChange(article)">
                                    <option
                                      v-for="(item) in article.replenishments"
                                      :key="item.id"
                                      :value="item.supplier_id">
                                      {{item.supplier_name}} <span v-if="item.is_preferred_supplier">(P)</span>
                                    </option>
                                  </select>
                                </div>
                              </td>
                              <td class="text-center"><span class="badge badge-primary lead-badge">{{article.suggested_order_quantity}} {{article.replenishment_ordering_uom_name}}</span></td>
                              <td>
                                <div class="gap-2 d-flex">
                                  <span class="badge badge-dark lead-badge">{{numeral(article.soh_by_replenishment).format('0,0[.]0[0]')}} {{article.replenishment_ordering_uom_name}}</span>
                                  <i
                                    v-if="article.inventory_stock_total"
                                    v-b-tooltip="{
                                      title: formattedSOH(article),
                                      html: true,
                                    }"
                                    class="fe fe-help-circle text-muted ml-1"></i>
                                </div>
                              </td>
                              <td v-if="filter.purchaseType === 'actual'" class="text-center">
                                <span class="badge badge-dark lead-badge">{{numeral(article.suggested_topup_quantity).format('0,0[.]00')}} {{article.replenishment_ordering_uom_name}}</span>
                              </td>
                              <td class="text-center"><span class="badge badge-info lead-badge">{{numeral(article.quantity_to_order).format('0,0[.]0[0]')}} {{article.replenishment_ordering_uom_name}}</span></td>
                              <td>
                                <div class="input-group input-group-sm input-group-flat">
                                  <input
                                    v-model.number="article.procurement_adjustment"
                                    type="number"
                                    placeholder="Enter adjustment"
                                    step="1"
                                    class="form-control form-control-sm text-end pe-0"
                                    style="font-size: small"
                                    @input="() => updateAdjustmentQty(article)">
                                  <span class="input-group-text" style="font-size: small">
                                    {{article.replenishment_ordering_uom_name}}
                                  </span>
                                </div>
                              </td>
                              <td class="text-center"><span class="badge badge-success lead-badge">{{numeral(article.decided_order_quantity).format('0,0[.]00')}} {{article.replenishment_ordering_uom_name}}</span></td>
                              <td class="text-center"><span class="badge badge-dark lead-badge">{{numeral(article.stock_on_order).format('0,0[.]00')}} {{article.replenishment_ordering_uom_name}}</span></td>
                              <td class="text-nowrap text-center">
                                {{moment(article.delivery_date).format('dddd')}} <br/>
                                <input
                                  v-model="article.delivery_date"
                                  :min="moment(article.curr_delivery_date).format('YYYY-MM-DD')"
                                  :max="moment(article.curr_delivery_date).add(3, 'week').format('YYYY-MM-DD')"
                                  format
                                  type="date"
                                  class="form-control form-control-sm"
                                  required/>
                              </td>
                            </tr>
                            <!-- TODO: this is for the packable breakdown -->
                            <!-- <template v-if="displayPackableArticles(article)">
                              <tr
                                v-for="n in 3"
                                :key="`${article.article_id}-sub-${n}`"
                                class="indented">
                                <td class="border-right-0"></td>
                                <td class="border-right-0 pl-4">
                                </td>
                                <td class="border-left-0 p-0" colspan="2">
                                  <div class="d-flex flex-column">
                                    <strong><router-link :to="`/articles/${article.article_id}`" target="_blank">{{article.article_name}}</router-link></strong>
                                  </div>
                                </td>
                                <td class="text-center white-space-nowrap">{{article.decided_order_quantity}}</td>
                                <td class="text-center">{{article.is_moving_stock}}</td>
                                <td>{{article.replenishment_ordering_uom_name}}</td>
                                <td class="text-center">{{numeral(article.replenishment_minimum_order_quantity).format('0,0[.]00')}}</td>
                                <td class="text-center">
                                  <div class="center gap-2">
                                    {{article.soh_by_replenishment}} {{article.replenishment_ordering_uom_name}}
                                    <i
                                      v-if="article.inventory_stock_total"
                                      v-b-tooltip="{
                                        title: formattedSOH(article),
                                        html: true,
                                      }"
                                      class="fe fe-help-circle text-muted"></i>
                                  </div>
                                </td>
                                <td v-if="filter.purchaseType === 'actual'" class="text-center">
                                  {{numeral(article.suggested_topup_quantity).format('0,0[.]00')}}
                                </td>
                                <td class="text-center">{{numeral(article.stock_on_order).format('0,0[.]00')}}</td>
                                <td class="text-center" colspan="2"></td>
                              </tr>
                            </template> -->
                          </template>
                          <tr v-if="!filteredResults.length">
                            <td class="text-center" colspan="100%">
                              <div class="d-flex align-items-center justify-content-center">
                                No articles to purchase. <button class="btn btn-link pl-0" @click.prevent="edit = true">Search again.</button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <edit-footer
                    :dirty="dirty"
                    entity="purchase order"
                    :isNew="true"
                    :submitting="submitting">
                  </edit-footer>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="showConfirmationDialog"
      title="Confirm selected articles to purchase"
      size="lg"
      id="modal-purchase-confirmation">
      <template slot="modal-header-close"><wbr/></template>

      <div class="table-responsive table-sm">
        <table class="table table-outline table-bordered table-striped table-hover table-vcenter">
          <thead>
            <tr>
              <th>Article</th>
              <th>Supplier</th>
              <th>Final Order</th>
              <th>Deliver Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="article in selectedArticlesWithQuantity" :key="`confirmation-${article.article_id}`">
              <td>{{article.article_name}}</td>
              <td>{{article.supplier_name}}</td>
              <td class="text-center"><small class="badge badge-dark">{{article.decided_order_quantity}} {{article.replenishment_ordering_uom_name}}</small></td>
              <td>{{moment(article.delivery_date).format('ddd DD MMM, YYYY')}}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <template slot="modal-footer">
        <button class="btn btn-secondary" @click.prevent="showConfirmationDialog = false">Cancel</button>
        <button class="btn btn-primary" @click.prevent="() => submitPurchaseOrder(true)">Confirm</button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Dimmer from '@/components/Dimmer.vue';
import EditFooter from '@/components/EditFooter';
import EditHeader from '@/components/EditHeader';
import download from '@/mixins/download';
import edit from '@/mixins/edit';
import sort from '@/mixins/sort';
import {articleForecast, articles, purchaseOrderRoster, purchaseOrders} from '@/services';
import {jsonToCsv} from '@/utils';
import {groupBy} from 'lodash';
import moment from 'moment-timezone';
import numeral from 'numeral';
import PurchaseOrderFilter from './filters/PurchaseOrderFilter';
import TableFilters from './filters/TableFilters';

export default {
  components: {
    Dimmer,
    EditFooter,
    EditHeader,
    PurchaseOrderFilter,
    TableFilters,
  },
  mixins: [
    download,
    edit,
    sort,
  ],
  data() {
    return {
      edit: true,
      feature: true,
      filter: {
        category: null,
        days: [],
        purchaseType: '',
        suppliers: [],
        weeklyMenu: null,
      },
      items: [],
      resultFilters: {
        suppliers: [],
        delivery_date: [],
      },
      searching: false,
      tableFilterList: {
        supplier_name: {
          label: 'supplier',
          model: 'suppliers',
        },
        delivery_date: {
          label: 'delivery date',
          model: 'delivery_date',
        },
      },
      showConfirmationDialog: false,
      successMessage: '',
    };
  },
  computed: {
    filteredResults() {
      let results = this.items || [];
      if (this.resultFilters.suppliers.length) {
        results = results.filter(item => this.resultFilters.suppliers.some(supplier => supplier === item.supplier_name));
      }
      if (this.resultFilters.delivery_date.length) {
        results = results.filter(item => this.resultFilters.delivery_date.some(date => date === item.delivery_date));
      }
      return results;
    },
    selectedArticles() {
      return this.filteredResults.filter(item => item.isSelected);
    },
    selectedArticlesWithQuantity() {
      return this.selectedArticles.filter(item => item.decided_order_quantity > 0);
    },
  },
  methods: {
    displayPackableArticles(article) {
      return !article.collapsed;
    },
    updateAdjustmentQty(article) {
      article.decided_order_quantity = Math.max(0, Math.ceil(Math.max(0, article.quantity_to_order) + article.procurement_adjustment));
    },
    formattedSOH(article) {
      let response = '';
      article.inventory_stock.filter(it => it.quantity).forEach(it => {
        response += `<li>${it.quantity} - ${it.pack_size}</li>`;
      });
      return `<div class="text-left d-flex flex-column gap-4">
        Stock on Hand: ${numeral(article.inventory_stock_total).format('0,0[.]0[0]')} Each
        <div>${response}</div>
        </div>`;
    },
    async exportForecast(type) {
      try {
        this.searching = true;

        const response = await articleForecast.forecastExport(type, {
          weekly_menu_id: this.filter.weeklyMenu.id,
          delivery_days: this.filter.days.map(d => d.id),
          category_id: this.filter.category.id,
        });

        this.download(response);
      }
      catch (e) {
        this.errorMessage = 'There is no data to download';
      }
      finally {
        this.searching = false;
      }
    },
    async applyFilter() {
      this.searching = true;

      const params = {
        category: this.filter.category.id,
        delivery_day: this.filter.days.map(day => day.id),
        type: this.filter.purchaseType,
        weekly_menu: this.filter.weeklyMenu.id,
      };

      if (this.filter.suppliers.length) {
        params.supplier = this.filter.suppliers.map(supplier => supplier.id);
      }

      try {
        const result = (await purchaseOrderRoster.getByParameters(params)).items;

        this.items = result.map(item => {
          const sohByReplenishmentSize = Number(parseFloat((Number(item?.inventory_stock_total ?? 0)) / (Number(item?.replenishment_pack_size ?? 0))).toFixed(2));
          const totalToDeduct = sohByReplenishmentSize;

          const suggestedOrderQuantityComputed = ((item?.suggested_order_quantity ?? 0) - totalToDeduct) + (item?.suggested_topup_quantity ?? 0);

          return Object.assign(item, {
            decided_order_quantity: Math.ceil(Math.max(0, suggestedOrderQuantityComputed)) || 0,
            quantity_to_order: suggestedOrderQuantityComputed,
            soh_by_replenishment: sohByReplenishmentSize,
            procurement_adjustment: 0,
            isSelected: false,
            curr_delivery_date: item.delivery_date,
            is_moving_stock: item.is_moving_stock ? 'Yes' : 'No',
            edit_supplier: false,
            loading: false,
            replenishments: [],
            collapsed: true,
          });
        });
      }
      catch (ex) {
        console.error(ex);
      }
      finally {
        this.searching = false;
        this.edit = false;
      }
    },
    async editSupplier(article) {
      article.loading = true;
      try {
        const {replenishments} = await articles.replenishments(article.article_id);

        if (replenishments.length <= 1) {
          this.$toasted.info('No alternate suppliers found for this article');
          return;
        }

        article.replenishments = replenishments;
        article.edit_supplier = true;
      }
      catch (error) {
        this.$toasted.error('Failed to fetch alternate suppliers');
      }
      finally {
        article.loading = false;
      }
    },
    onSupplierChange(article) {
      const replenishment = article.replenishments.find(item => item.supplier_id === article.supplier_id);
      article.supplier_name = replenishment?.supplier_name;
      article.edit_supplier = false;
    },
    toggleAllCheckbox(value) {
      this.filteredResults.forEach(item => {
        item.isSelected = value;
      });
    },
    exportTable() {
      if (this.selectedArticlesWithQuantity.length === 0) {
        this.$toasted.error('Please select articles to export');
        return;
      }

      const includeColumns = ['article_id',
        'article_name', 'supplier_name',
        'replenishment_ordering_uom_name',
        'category', 'suggested_order_quantity',
        'suggested_topup_quantity', 'pack_size',
        'stock_on_order', 'is_moving_stock',
        'quantity_to_order', 'decided_order_quantity'];

      jsonToCsv(this.selectedArticlesWithQuantity, {
        filename: 'roster_purchase_summary',
        includeColumns,
      });
    },
    async submitPurchaseOrder(confirm = false) {
      this.error = false;
      this.response = {
        data: null,
        error: {},
      };
      this.invalid = false;
      this.invalidMessage = '';
      this.submitted = false;

      if (!confirm) {
        // show confirmation dialog
        this.showConfirmationDialog = true;
        return;
      }

      const form = this.$refs.form;
      const valid = await this.validate() && form.checkValidity();

      if (valid) {
        this.submitting = true;

        try {
          // get payload for purchase order by grouping selected articles by supplier and delivery date
          const groupedArticles = groupBy(this.selectedArticlesWithQuantity, 'supplier_id');
          const payload = Object.values(groupedArticles).map(articles => {
            const supplierId = articles[0].supplier_id;
            const groupedByDeliveryDate = groupBy(articles, 'delivery_date');
            const deliveryDates = Object.keys(groupedByDeliveryDate);

            return deliveryDates.map(deliveryDate => ({
              articles: groupedByDeliveryDate[deliveryDate].map(article => ({
                article_id: article.article_id,
                category_id: article.category_id,
                decided_qty: article.decided_order_quantity,
                oum_id: article.replenishment_ordering_uom_id,
                qb_name: article.replenishment_quickbook_name,
                suggested_qty: article.suggested_order_quantity,
              })),
              delivery_date: moment(deliveryDate).format('YYYY-MM-DD'),
              delivery_days: this.filter.days.map(day => day.id),
              lead_time: groupedByDeliveryDate[deliveryDate][0].replenishment_lead_time,
              supplier_id: supplierId,
              weekly_menu_id: this.filter.weeklyMenu.id,
              purchase_type: this.filter.purchaseType,
            }));
          }).flat();

          await purchaseOrders.place({purchase_orders: payload || []});

          this.completed();
          this.successMessage = 'Successfully placed purchase order';
          this.submitted = true;
        }
        catch (ex) {
          if (ex.response && ex.response.status === 422) {
            this.invalid = true;
            this.response = {data: this.response.data || this.item, error: ex.response.data.errors};

            const parsedErrors = Object.values(ex.response.data.errors).map(item => item.join(', ')).join(', ');
            this.$toasted.error(parsedErrors);
          }
          else {
            this.error = true;
            throw ex;
          }
        }
        finally {
          this.submitting = false;
          this.showConfirmationDialog = false;
        }
      }
      else {
        this.invalid = true;
      }

      form.classList.toggle('was-validated', this.invalid);
      window.scrollTo(0, 0);
    },
    completed() {
      if (!this.invalid) {
        // reset data fields
        Object.assign(this.$data, {
          edit: true,
          feature: true,
          filter: {

            category: null,
            days: [],
            purchaseType: '',
            suppliers: [],
            weeklyMenu: null,
          },
          items: [],
          resultFilters: {
            suppliers: [],
            delivery_date: [],
          },
          searching: false,
        });

        this.$refs.filter.$v.$reset();
      }
    },
  },
};
</script>

<style scoped>
    .big-checkbox >>> span > .fe {
        font-size: 2rem;
    }

    .sticky-table thead th {
        position: -webkit-sticky !important;
        position: sticky !important;
        top: 0;
        z-index: 20;
        border-right-width: 1px;
        border-left-width: 1px;
    }

    .sticky-table thead th:after {
        right: 2px;
        bottom: 1rem;
    }
    .sticky-table thead th:before {
        right: 2px;
        top: 1rem;
    }

    .table-success td {
        border-color: #c5e7a4;
    }

    .table th {
      font-size: x-small;
    }

    .table td {
      font-size: small;
    }

    .badge.lead-badge {
      font-size: small;
    }
</style>
