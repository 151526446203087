<template>
  <div class="card market-recipe-selection-card mb-0 border-0">
    <div v-if="withHeader" class="card-header">
      <h3 class="card-title" data-test="label-selection-header">{{caption}} ({{totalSelected}} selected)</h3>
      <div v-if="filter" class="card-options">
        <input
          v-model="query"
          type="text"
          class="form-control"
          placeholder="Filter"/>
      </div>
    </div>
    <div class="market-recipes-container">
      <table class="table table-vcenter text-nowrap card-table">
        <tbody>
          <template v-for="(recipes, key, index) in transformedMarketRecipes">
            <tr :key="`${key}-${index}-header`">
              <th class="p-0 text-default">
                <h5>{{key}}</h5>
              </th>
            </tr>
            <tr
              v-for="item in recipes"
              :key="item.id"
              :class="{'row-selected': quantity(item.id) > 0}">
              <td class="recipe-selector-container">
                <recipe-selector
                  v-if="!readOnly"
                  :data-test="`market-recipe-selector-${item.id}`"
                  class="recipe-selector"
                  :quantity="quantity(item.id)"
                  :max="max"
                  :disabled="totalSelected >= max"
                  :name="item.name"
                  :number="item.id"
                  :price="item.price"
                  :delivery-split="deliverySplit(item.id)"
                  @change="quantity => setRecipeQuantity({recipe_id: item.id, quantity})"
                />
                <div v-else class="readonly-container">
                  <p>
                    {{item.id}}. {{item.name}}
                  </p>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import RecipeSelector from '@/components/shared/recipe-selector/index.vue';
import {MARKET_ITEM_TYPES} from '@/constants/market-item-types';

export default {
  name: 'MarketRecipeSelectionCard',
  components: {
    RecipeSelector,
  },
  props: {
    caption: String,
    disabled: {default: () => false, type: Boolean},
    filter: {default: true},
    items: Array,
    max: Number,
    readOnly: Boolean,
    selections: {
      type: Array,
      default: () => [],
    },
    totalSelected: Number,
    withHeader: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      query: '',
      sortedItems: [],
    };
  },
  computed: {
    shownItems() {
      return this.sortedItems.filter(item => this.query === '' || item.name.split(' ').join('').toLowerCase().includes(this.query.split(' ').join('').toLowerCase()));
    },
    transformedMarketRecipes() {
      const marketRecipes = {};

      Object.values(MARKET_ITEM_TYPES).forEach(type => {
        const recipes = this.shownItems
          .filter(recipe => recipe.type === type)
          .map(this.mapMarketRecipesToGetPrice);

        // Only add the type if there are recipes scheduled for the week
        if (recipes.length > 0) {
          marketRecipes[type.toLowerCase()] = recipes;
        }
      });

      return marketRecipes;
    },
  },
  watch: {
    items: {
      handler() {
        this.sortedItems = this.sortRecipesBySelections();
      },
      immediate: true,
    },
  },
  methods: {
    setRecipeQuantity(selection) {
      this.$emit('input', selection);
    },
    mapMarketRecipesToGetPrice(recipe) {
      const price = Number(recipe.unit_price) + Number(recipe.vat);

      return {
        ...recipe,
        price,
      };
    },
    sortRecipesBySelections() {
      // sort by not mutating the original array
      const toSortItems = structuredClone(this.items);

      return toSortItems.sort((a, b) => {
        const aSelected = this.selections.find(selected => selected.recipe_id === a.id);
        const bSelected = this.selections.find(selected => selected.recipe_id === b.id);

        if (aSelected && bSelected) {
          return aSelected.quantity > bSelected.quantity ? -1 : 1;
        }
        if (aSelected) {
          return -1;
        }
        if (bSelected) {
          return 1;
        }
        return 0;
      });
    },
    quantity(id) {
      return this.selections.find(selected => selected.recipe_id === id)?.quantity || 0;
    },
    deliverySplit(id) {
      return this.selections.find(selected => selected.recipe_id === id)?.delivery_split_number;
    },
  },
};
</script>

<style scoped>
.market-recipe-selection-card {
  max-height: 560px;
}

.market-recipes-container {
  overflow-y: scroll;

  h5 {
    margin: 0;

    padding: 16px 24px;
    border-bottom: 1px solid #e9ecef;
    text-transform: capitalize;
  }
}

.recipe-selector-container {
  padding: 0 !important;

  border-bottom: 1px solid #e9ecef;

  &:hover {
    background-color: #E0E5EC;
  }
}

.recipe-selector {
  >>> .truncate-recipe {
  width: 360px;
  }
}

.row-selected {
  background-color: #d2ecb8;

  &:hover {
    background-color: #d2ecb8;
  }
}

.readonly-container {
  display: flex;

  p {
    margin: 0;
    padding: 8px 20px;
  }
}
</style>
